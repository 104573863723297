import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import Carousel from "react-simply-carousel";
import { SolanaConnect } from "solana-connect";

import Web3Methods from './Web3Methods.js';
import './index.css'; // Importa el CSS personalizado

import kitchen from './images/backgroundflan2024.png';
import download from './images/download.png';
import downloadMobile from './images/downloadMobile.png';
import randomMobile from './images/randomMobile.png';
import connect from './images/connect.png';
const AvatarConstructor2 = () => {
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [bodyColor, setBodyColor] = useState(null);
  const [shirt, setShirt] = useState(null);
  const [moustache, setMoustache] = useState(null);
  const [eyes, setEyes] = useState(null);
  const [glasses, setGlasses] = useState(null);
  const [nose, setNose] = useState(null);
  const [headAccesory, setHeadAccesory] = useState(null);

  const [activeSlideBackground, setActiveSlideBackground] = useState(0);
  const [activeSlideBodyColor, setActiveSlideBodyColor] = useState(0);
  const [activeSlideShirt, setActiveSlideShirt] = useState(0);
  const [activeSlideMoustache, setActiveSlideMoustache] = useState(0);
  const [activeSlideEyes, setActiveSlideEyes] = useState(0);
  const [activeSlideGlasses, setActiveSlideGlasses] = useState(0);
  const [activeSlideNose, setActiveSlideNose] = useState(0);
  const [activeSlideHeadAccesory, setActiveSlideHeadAccesory] = useState(0);
  const [overlay, setOverlay] = useState(true);
  const canvasRef = useRef(null);
  const [message, setMessage] = useState("CONNECT WALLET TO COOK YOUR OWN CUSTOM FLAN");
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const [solConnect, setSolConnect] = useState(null);

  let RPCMethods = Web3Methods.getInstance();

  const getFlanPrice = async() => {
    let result = 0;
    await axios.get("https://price.jup.ag/v6/price?ids=FLAN").then((response) => {
      result =  response.data.data.FLAN.price;
    });
    return result;
  }

  useEffect(() => {
    if(!solConnect){
      let tmp = new SolanaConnect();
      tmp.onWalletChange(async (adapter) => {
          if(adapter){
            //setWalletAddress(adapter.publicKey.toString());
            let flanBalance = await RPCMethods.getTokenAccountsByOwner(adapter.publicKey.toString());
            //setBalance(flanBalance);
            let price = await getFlanPrice();
            if(flanBalance * price > 50){
              setOverlay(false);
            }else{
              setMessage("YOU NEED TO HOLD AT LEAST $50 FLAN TO COOK YOUR OWN CUSTOM FLAN. YOU HAVE $" + Number(flanBalance * price).toFixed(2).toString());
            }
          }
        }
      );
      
      tmp.onVisibilityChange((isOpen) => {
        console.log("menu visible:", isOpen);
      });

      
      setSolConnect(tmp);
    }
  }, [])
  
  

  const openConnect = () => {
    solConnect.openMenu();
  }

  const importAll = (r) => {
    let images = [];
    r.keys().map((item) =>  images.push(r(item)));
    return images;
  }
  
  const imagesBackground = importAll(require.context('./images/1-backgrounds', false, /\.(png|jpe?g|svg)$/));
  const imagesBodyColor = importAll(require.context('./images/2-bodycolor', false, /\.(png|jpe?g|svg)$/));
  const imagesShirts = importAll(require.context('./images/2-1-shirt', false, /\.(png|jpe?g|svg)$/));
  const imagesMoustache = importAll(require.context('./images/3-moustache', false, /\.(png|jpe?g|svg)$/));
  const imagesEyes = importAll(require.context('./images/4-eyes', false, /\.(png|jpe?g|svg)$/));
  const imagesGlasses = importAll(require.context('./images/4-1-glasses', false, /\.(png|jpe?g|svg)$/));
  const imagesNoses = importAll(require.context('./images/5-nose', false, /\.(png|jpe?g|svg)$/));
  const imagesHead = importAll(require.context('./images/6-headaccesories', false, /\.(png|jpe?g|svg)$/));

  useEffect( () => {
    if(!overlay){
      const dragImage = (image) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let image1 = new Image();
        image1.src = image;
        image1.onload = () => {
          ctx.drawImage(image1, 0, 0, canvas.width, canvas.height);
        };
        return ctx;
      }
  
      const drawImageInCanva = async () => {
        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');
        if(backgroundColor != null){ctx = dragImage(backgroundColor); } await sleep(10);
        if(bodyColor != null){ ctx = dragImage(bodyColor); } await sleep(15);
        if(shirt != null){ ctx = dragImage(shirt); }await sleep(20);
        if(moustache != null){ ctx = dragImage(moustache); }await sleep(25);
        if(eyes != null){ ctx = dragImage(eyes); }await sleep(30);
        if(glasses != null){ ctx = dragImage(glasses); }await sleep(35);
        if(nose != null){ ctx = dragImage(nose); }await sleep(40);
        if(headAccesory != null){ ctx = dragImage(headAccesory); }await sleep(45);
      }
      drawImageInCanva();
    }
    
  }, [backgroundColor, bodyColor, shirt, moustache, eyes, glasses, nose, headAccesory]);

  const handleBackgroundColorChange = async (newColor) => { setBackgroundColor(newColor); };
  const handleBodyColorChange = async (newColor) => { setBodyColor(newColor); };
  const handleShirtChange = async (newColor) => { setShirt(newColor) };
  const handleMoustacheChange = async (newColor) => { setMoustache(newColor); };
  const handleEyesChange = async (newColor) => { setEyes(newColor); };
  const handleGlassesChange = async (newColor) => { setGlasses(newColor); };
  const handleNoseChange = async (newColor) => { setNose(newColor); };
  const handleHeadAccesoryChange = async (newColor) => { setHeadAccesory(newColor); };

  const backgroundCarouselList = imagesBackground.map(function(color, index) {
    return(
      <div onClick={() => handleBackgroundColorChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
        key={index}>
    </div>
    )
  });
  const bodyColorCarouselList = imagesBodyColor.map(function(color, index) {
    return(
      <div onClick={() => handleBodyColorChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const shirtCarouselList = imagesShirts.map(function(color, index) {
    return(
      <div onClick={() => handleShirtChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const moustacheCarouselList = imagesMoustache.map(function(color, index) {
    return(
      <div onClick={() => handleMoustacheChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const eyesCarouselList = imagesEyes.map(function(color, index) {
    return(
      <div onClick={() => handleEyesChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const glassesCarouselList = imagesGlasses.map(function(color, index) {
    return(
      <div onClick={() => handleGlassesChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const noseCarouselList = imagesNoses.map(function(color, index) {
    return(
      <div onClick={() => handleNoseChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });
  const headCarouselList = imagesHead.map(function(color, index) {
    return(
      <div onClick={() => handleHeadAccesoryChange(color.toString())} style={{ backgroundImage: "url("+color.toString()+")", backgroundSize: "cover", width: 60, height: 60, marginLeft: "10px", textAlign: "center", lineHeight: "100px", boxSizing: "border-box" }}
      key={index}>
      </div>
    )
  });

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const clearImages = () => {
    setBackgroundColor(null); setBodyColor(null); setShirt(null); setMoustache(null); 
    setEyes(null); setGlasses(null); setNose(null); setHeadAccesory(null);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }
  const handleGenerateRandomAvatar = async () => {
    clearImages();
    let randomNumber1 = randomNumberInRange(0, imagesBackground.length -1);
    let randomNumber2 = randomNumberInRange(0, imagesBodyColor.length -1);
    let randomNumber3 = randomNumberInRange(0, imagesShirts.length -1);
    let randomNumber4 = randomNumberInRange(0, imagesMoustache.length -1);
    let randomNumber5 = randomNumberInRange(0, imagesEyes.length -1);
    let randomNumber6 = randomNumberInRange(0, imagesGlasses.length -1);
    let randomNumber7 = randomNumberInRange(0, imagesNoses.length -1);
    let randomNumber8 = randomNumberInRange(0, imagesHead.length -1);
    await handleBackgroundColorChange(imagesBackground[randomNumber1]);
    await handleBodyColorChange(imagesBodyColor[randomNumber2]);
    await handleShirtChange(imagesShirts[randomNumber3]);
    await handleMoustacheChange(imagesMoustache[randomNumber4]);
    await handleEyesChange(imagesEyes[randomNumber5]);
    await handleGlassesChange(imagesGlasses[randomNumber6]);
    await handleNoseChange(imagesNoses[randomNumber7]);
    await handleHeadAccesoryChange(imagesHead[randomNumber8]);
  };

  const handleDownloadAvatar = () => {
    const canvas = canvasRef.current;
    const link = document.createElement('a');
    link.download = 'avatar.png';
    link.href = canvas.toDataURL();
    link.click();
  };
  
  return (
    <div className='container' id="flanGeneratorContainer">
      <span id="cookVersion">COOK YOUR OWN FLAN: BETA V0.2</span>
        {overlay && (
            <div className="col-12" id="messageConnect">
                <h1 id="message" style={{textAlign: "center", color: "#6684C1"}}>{message}</h1>
                <img src={connect} id="walletButton" alt="connect wallet"  onClick={openConnect} />
            </div>
        )}
        {!overlay && (
            <div>
                <div className="row" id="randomAvatarButtonContainer">
                    <img src={randomMobile} id="randomMobileButton" onClick={handleGenerateRandomAvatar}/>
                </div>
                <div className="row">
                    <canvas ref={canvasRef} className="avatar-canvas" width={1000} height={1000} />
                </div>
                <div className="row" id="downloadAvatarButtonContainer">
                    <img src={downloadMobile} id="downloadMobileButton" onClick={handleDownloadAvatar}/>
                </div>
                <div id="randomButton" onClick={handleGenerateRandomAvatar}></div>
                <div className="row" id="optionsContainer">                        
                    <h2>Background</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideBackground}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideBackground}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {backgroundCarouselList}
                    </Carousel>
                    <h2>Base color</h2>
                    
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideBodyColor}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideBodyColor}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {bodyColorCarouselList}
                    </Carousel>
                    <h2>Shirt</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideShirt}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideShirt}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {shirtCarouselList}
                    </Carousel>
                    <h2>Moustache</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideMoustache}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideMoustache}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {moustacheCarouselList}
                    </Carousel>
                    <h2>Eyes</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideEyes}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideEyes}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {eyesCarouselList}
                    </Carousel>
                    <h2>Glasses</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideGlasses}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideGlasses}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {glassesCarouselList}
                    </Carousel>
                    <h2>Nose</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideNose}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideNose}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={2} speed={400} centerMode>
                        
                        {noseCarouselList}
                    </Carousel>
                    <h2>Moustache</h2>
                    <Carousel
                        containerProps={{ style: { width: "100%", justifyContent: "space-between", userSelect: "none" } }}
                        preventScrollOnSwipe swipeTreshold={60} activeSlideIndex={activeSlideHeadAccesory}
                        activeSlideProps={{ style: { border: "solid black 2px" } }} onRequestChange={setActiveSlideHeadAccesory}
                        forwardBtnProps={{ children: ">", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        backwardBtnProps={{ children: "<", style: { width: 20, height: 20, minWidth: 20, alignSelf: "center" } }}
                        dotsNav={{ show: false, itemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0 } },
                        activeItemBtnProps: { style: { height: 16, width: 16, borderRadius: "50%", border: 0, background: "black" } }
                        }} itemsToShow={4} speed={400} centerMode>
                        
                        {headCarouselList}
                    </Carousel>
                </div>
                <img src={download} id="downloadButton" alt="DOWNLOAD" onClick={handleDownloadAvatar}  />
            </div>
        )}
    </div>
  )
};

export default AvatarConstructor2;
