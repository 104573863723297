import './index.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import socialMedia from '../../assets/images/socialMediaTransparent.webp';
import chefIcon from '../../assets/images/chefIcon.svg';
import foodPan from '../../assets/images/foodPan.svg';
import flanLogo from '../../assets/images/flanLogo.webp';
import battleMusic from '../../assets/images/battleMusic.webp';
import flan from '../../assets/images/flan.webp';
import yellowPlay from '../../assets/images/yellowPlay.webp';
import musicNotes from '../../assets/images/musicNotes.svg';
import copyRight from '../../assets/images/copyright.svg';
import darkRRSSCloud from '../../assets/images/darkRRSSCloud.svg';
import telegramIcon from '../../assets/images/telegramIcon.webp';
import twitterIcon from '../../assets/images/twitterIcon.webp';
import instagramIcon from '../../assets/images/instagramIcon.webp';
import buyFlancoin from '../../assets/images/buyFlancoin.webp';
import dialog1 from '../../assets/images/dialog.svg';
import dialog2 from '../../assets/images/dialog2.svg';
import flandersMenu from '../../assets/images/flandersMenu.webp';
import flanMenu from '../../assets/images/flanMenu.webp';
import cloud from '../../assets/images/cloud.svg';
import dexScreener from '../../assets/images/dexScreener.svg';
import dexTools from '../../assets/images/dexTools.svg';
import thinking from '../../assets/images/thinking.webp';
import thinkingText from '../../assets/images/thinkingText.svg';
import raydium from '../../assets/images/raydium.webp';
import jupiter from '../../assets/images/jupiter.webp';
import buttonBackground from '../../assets/images/buttonBackground.webp';
import solana from '../../assets/images/solana.webp';
import coinHunt from '../../assets/images/coinhunt.webp';
import coinHunters from '../../assets/images/coinHunters.webp';
import gottaSneakers from '../../assets/images/gottaSneakers.webp';
import search from '../../assets/images/search.svg';
import solflare from '../../assets/images/solflare.webp';
import sol from '../../assets/images/sol.webp';
import arrow from '../../assets/images/arrow.svg';
import coin from '../../assets/images/coin.webp';
import logoCoin from '../../assets/images/logoCoin.webp';
import wallet from '../../assets/images/wallet.webp';
import cookYourOwnFlan from '../../assets/images/cookYourOwnFlan.png';
import flanChef from '../../assets/images/flanChef.webp';
import revoked from '../../assets/images/revoked.webp';
import burned from '../../assets/images/burned.webp';
import tax from '../../assets/images/tax.webp';
import supply from '../../assets/images/supply.webp';
import team from '../../assets/images/team.webp';
import marketing from '../../assets/images/marketing.webp';
import gameRewards from '../../assets/images/gameRewards.webp';


function root() {   
  const caCode = process.env.REACT_APP_CA_CODE;
  const linkTelegram = process.env.REACT_APP_TELEGRAM;
  const linkTwitter = process.env.REACT_APP_TWITTER;
  const linkInstagram = process.env.REACT_APP_INSTAGRAM;
  const linkBuyFlancoin = process.env.REACT_APP_BUY_FLANCOIN;
//   const linkPlay = process.env.REACT_APP_PLAY_VIDEO;
//   const linkVideo1= process.env.REACT_APP_BATTLE_MUSIC_1;
//     const showVideo1= process.env.REACT_APP_BATTLE_MUSIC_1_ENABLE;
//     const linkVideo2= process.env.REACT_APP_BATTLE_MUSIC_2;
//     const showVideo2= process.env.REACT_APP_BATTLE_MUSIC_2_ENABLE;
//     const linkVideo3= process.env.REACT_APP_BATTLE_MUSIC_3;
//     const showVideo3= process.env.REACT_APP_BATTLE_MUSIC_3_ENABLE;
//     const linkVideo4= process.env.REACT_APP_BATTLE_MUSIC_4;
//     const showVideo4= process.env.REACT_APP_BATTLE_MUSIC_4_ENABLE;
//     const linkVideo5= process.env.REACT_APP_BATTLE_MUSIC_5;
//     const showVideo5= process.env.REACT_APP_BATTLE_MUSIC_5_ENABLE;

const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(caCode);
      withReactContent(Swal).fire({
        iconHtml: '<img src='+flanLogo+' id="flanSwal"/>',
        text: "Contract address copied to clipboard: " + caCode
      });
    } catch (error) {
      withReactContent(Swal).fire({
        icon: 'error',
        text: 'Error copying to clipboard: '+ error
      });
    }
  }

  const linkSponsorDextools = process.env.REACT_APP_SPONSOR_DEXTOOLS;   
  const linkSponsorDexscreener = process.env.REACT_APP_SPONSOR_DEXSCREENER;

  const linkSponsorJupiter = process.env.REACT_APP_SPONSOR_JUPITER;    
  const linkSponsorRaydium = process.env.REACT_APP_SPONSOR_RAYDIUM;   
  const linkSponsorSolana = process.env.REACT_APP_SPONSOR_SOLANA;    
  
  const linkSponsorCoinhunters = process.env.REACT_APP_SPONSOR_COINHUNTERS;
  const linkSponsorCoinhunt = process.env.REACT_APP_SPONSOR_COINHUNT;
  
  const linkSponsorGottasneakers = process.env.REACT_APP_SPONSOR_GOTTASNEAKERS;
  return (
    <div>
      <div id="root">
      
    <section id="qRIvjXAAVdQTu1kX" style={{ position: "relative", overflow: "hidden", display: "grid", alignItems: "center", gridTemplateColumns: "auto 100rem auto", zIndex: 0 }}>
        <div id="Gy37jgYuikbgVbR3" style={{ gridArea: "1 / 1 / 2 / 4", display: "grid", position: "absolute", minHeight: "100%", minWidth: "100%" }}>
            <div id="xd3yRpPmzJz0Hsvk" style={{ zIndex: 0 }}>
                <div id="X5ZXzGL0bwHeRaN2" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="P36pc2jVIg59SHfk" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="ooLBhOYSmFysKvLw" style={{ backgroundColor: "#2596ff", opacity: 1.0, transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }} />
                    </div>
                </div>
            </div>
        </div>
        
        <div id="yn8YdRIfv55WlHvy" style={{ display: "grid", position: "relative", gridArea: "1 / 2 / 2 / 3" }}>
            {/*OnLoadSection*/}
            <div id="EAX3IirQ6HgfOaX5" style={{ zIndex: 2 }}>
                <div id="VBY3X04heqUYYlAy" style={{ paddingTop: "82.06723941%", transform: "rotate(0deg)" }}>
                    <div id="mhLaCBaLiN6VnXYc" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="Q0IwtjCIO9lDVgcI" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="S5DJmCovp40hVK0X" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="C55l2nXczKLi6jkr" style={{ width: "calc(345.82187566% * max(1, var(--scale-fill, 1)))", height: "calc(130.32632759% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                    <a href={linkTelegram} target="_blank" rel="noreferrer"><img src={socialMedia} alt="social media"
                                    loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "20.2492661% 38.36523358%", transform: "translate(-20.2492661%, -38.36523358%) rotate(0deg)" }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Kq50BmTuxglBJuMH" style={{ zIndex: 107 }}>
                <div id="QWrYFSbB2vBsJhDZ" style={{ paddingTop: "76.80933905%", transform: "rotate(0deg)" }}>
                    <div id="IBjQTMPmRYgrgJCB" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="UdFoWXcmwQ5dIRPa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="jNQpf4pnSnjG1U3b" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="q6TVeOZE6wGFCfto" style={{ width: "calc(323.66569034% * max(1, var(--scale-fill, 1)))", height: "calc(130.32632759% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                    <a href={linkTwitter} target="_blank" rel="noreferrer"><img src={socialMedia} alt="social media"
                                    loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "48.37500959% 38.36523358%", transform: "translate(-48.37500959%, -38.36523358%) rotate(0deg)" }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="l0rCr8Dqe1PMNqn9" style={{ zIndex: 108 }}>
                <div id="hITYnbZW6KSVkxqz" style={{ paddingTop: "72.14118783%", transform: "rotate(0deg)" }}>
                    <div id="RQz5PoQEeV3EKIHw" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="XcGfuHsWLtCI2wt7" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="LrSev7fildvRSkqX" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="KDgZ2etkqY8mmkAz" style={{ width: "calc(303.99463984% * max(1, var(--scale-fill, 1)))", height: "calc(130.32632759% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                    <a href={linkInstagram} target="_blank" rel="noreferrer"><img src={socialMedia} alt="social media"
                                    loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "76.96291206% 38.36523358%", transform: "translate(-79.96291206%, -38.36523358%) rotate(0deg)" }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="OgwtvAb4gvMo6Sbj" style={{ zIndex: 51 }}>
                <div id="gGA8rZeGtfcdG643" style={{ paddingTop: "30.71342201%", transform: "rotate(0deg)" }}>
                    <div id="JPpyVbIh9BEvudfr" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="XLqEP6KBD5WA61Qr" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="lBKaZWNZjqBYKbvR" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="CJXpLwMTeZeqNDTe" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1}}> 
                                    <a href={linkBuyFlancoin} target="_blank" rel="noreferrer"><img src={buyFlancoin} alt="buy flancoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="yKFqIYlWlGVsWvGL" style={{ zIndex: 1 }}>
                <div id="UnZB9TiN6xnwOSle" style={{ paddingTop: "48.69808561%", transform: "rotate(0deg)" }}>
                    <div id="BWZ113UJGyJlosiU" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="iWSEpGqWCCkf9CZB" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="mWyPMGjEzSMySFFp" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="vrxr927EClfirq1w" style={{ width: "calc(137.47292659% * max(1, var(--scale-fill, 1)))", height: "calc(113.80072304% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}> 
                                    <img src={flan} alt="flan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 47.2438418%", transform: "translate(-50%, -47.2438418%) rotate(0deg)" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="GIBpEOKyN5UepiWj" style={{ zIndex: 78 }}>
                <div id="GtRDBQrzujN77BW5" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="to0eY6dfEKAZ8iT7" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                        <p id="xodneXxadjC1NBqs" style={{ color: "#ffffff", fontFamily: "CC-Maladroit", lineHeight: "1.4em", textAlign: "center" }}> <span id="Q5ervVry36LLdXHG" style={{ color: "#ffffff" }}> Invest in the most irresistible sweetness of the blockchain ! </span> </p>
                    </div>
                </div>
            </div>
            <div id="HNPmDhAXTVEq0gVL">
                <div id="gp8t5osSHBx5Qc0k" style={{ display: "grid", position: "relative" }}>
                    <div id="sxM2L4JpQNhbvYGv" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>
                        <div id="GNEFD0xgjuNexA6P" style={{ zIndex: 4 }}>
                            <div id="kAlOR4TJxTItWbuO" style={{ paddingTop: "27.75%", transform: "rotate(0deg)" }}>
                                <div id="Bm2n3lqR3kPyDbEz" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="pLp2mKCAGWwASzCL" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="x5NDl8XT4vkh1IRS" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="WPTCkukIfBzv0spi" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1 }}> 
                                                <img src={dialog1} alt="Speech Bubble Illustration" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="IOUW2rTRNuPs1dCE" style={{ zIndex: 5 }}>
                            <div id="BD3FEXmzXSjZySoI" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="TK0ux00b5ulg1p75" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="HTJQHg0PydtWTb4A" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.4em", textAlign: "center" }}> <span id="ZT7l6R2Q578fcDuf" style={{ color: "#2b384c" }}> join community </span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*END OnLoadSection*/}
            {/*MenuSection*/}
            <div id="dg0OLVlRNmVhKh9h">
                <div id="dNvlrVLRJvacM3R6" style={{ display: "grid", position: "relative" }}>
                    <div id="HEZLRrbM5tiqAP0x" style={{ zIndex: 8 }}>
                        <div id="FQ5xn4Mox4R1pGSG" style={{ paddingTop: "68.99330171%", transform: "rotate(0deg)" }}>
                            <div id="mq8MBPHSATyginO9" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="lPcWlNZZ9l3ECH5A" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="qmwaR3NKuLuYy1d1" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="oLRY3PdspHS0xQdy" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(137.01511257% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={flandersMenu} alt="flanders menu" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 36.49232487%", transform: "translate(-50%, -36.49232487%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ftz0rxqtTI9C5U2v" style={{ zIndex: 12 }}>
                        <div id="JlBWnsjHJm6U3WQi" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="ZwJs1dFsVJazAU3x" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="J9Wfs6UbGOHahhWe" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="Lmk0XFvO0JYxf7Ta" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="ZcdqUKAxNarlqnL8" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                        <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ZTMCm8nRrVjgQZxA" style={{ zIndex: 14 }}>
                        <div id="YqDdFO0jyn5rXP72" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="hAAaxPIqFGqBCHZ1" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="ytNfNxM7HHYBqWzr" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="gHev34LTHvifmqXe" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="SdjR8VZZbEddjAIe" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                        <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="zx4s5js0F6BLyYrX">
                <div id="lG8px8CyQJ96HT4N" style={{ display: "grid", position: "relative" }}>
                    <div id="QWHBx17olLoC3Vze" style={{ zIndex: 6 }}>
                        <div id="IxB6xJADJnNLLkdr" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(-180deg)" }}> <svg id="HPSJ6QhpG6Jc48qe" viewBox="0 0 216.241 76.0576" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: "0.3", overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="R28IB1CxUK9V4eqg" style={{ transform: "scale(1, 1)" }}>
                                    <path id="dh7KgOjaJuD5q1u9" d="M216.24100448,0 L216.24100448,67.05755424 L108.12050224,76.05755424 L0,67.05755424 L0,0 L216.24100448,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                                </g>
                            </svg> </div>
                    </div>
                    
                    <div id="WDUOFiNdNFER5XOb" style={{ zIndex: 22 }} className="flanMenu">
                        <div id="r0Tj1RpEBuqgLsV8" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                            <div id="MYSVobRzNN6yTW4M" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="mCQuNDvTryAgzZXx" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="AsfCfr32At1QgFqZ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <p  className="textMenu" style={{position: "absolute", zIndex: 24, width: "100%", fontFamily: "CC-Maladroit", textAlign: "center"}}>About us</p>
                                        <div id="Xzr4zDMJqY3Q9tim" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href="#B3touXT8hQVYufku"><img src={flanMenu}  alt="flanMenu" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="XWt54qcabALKRjiP" style={{ zIndex: 23 }} className="flanMenu">
                        <div id="oLjQnLu7DyzPFJNM" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                            <div id="YETndOqfHFwsy2U1" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="evnJMMqoHNAj8Ibg" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="XoIlhsRuoQJfqNlT" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <p  className="textMenu" style={{position: "absolute", zIndex: 24, width: "100%", fontFamily: "CC-Maladroit", textAlign: "center"}}>Tokenomics</p>
                                        <div id="xMiATmMOCZQGIHQ5" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            
                                           <a href="#eG8jG7lCD9BvzIzK"> <img src={flanMenu}  alt="flanMenu" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="JrP46tNUeElBiD16" style={{ zIndex: 10 }}>
                        <div id="M3fpVNqR0v626flf" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="Azt1GmQkue8nHr7F" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="mCdTV5hwiFH8vMQo" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="Zdrkw8B90K2WZzlP" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="dMIY0qlFBlZmJH4h" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="PYbsw4kHmh3QbjnQ" style={{ zIndex: 9 }}>
                        <div id="LdPDSG8Xd6blHJPk" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="akkr1OOmSwgoCtqj" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="DGm3YtfkdIxQmhEV" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="Gut3IMGr0n5nwaHr" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="ttx8k356oFIGRl24" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="BT6z4edZctfaiNny" style={{ zIndex: 24 }} className="flanMenu">
                        <div id="srF9ZFU96w8GPzc7" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                            <div id="jaxxN7hxJCTeSlFB" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="L5EVLRcswsBL4Urm" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="nPtlxEqMw18nbXI5" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <p  className="textMenu"  style={{position: "absolute", zIndex: 24, width: "100%", fontFamily: "CC-Maladroit", textAlign: "center"}}>How to buy</p>
                                        <div id="MB5nFZZwknkvTy8Q" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href="#EGpfzmaYJiovQgkg"><img src={flanMenu}  alt="flanMenu" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="e16xHG5l0un1O992" style={{ zIndex: 25 }} className="flanMenu">
                        <div id="N6N8GIYTfsrjzq5K" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                            <div id="ap3p4nGHM96Xpbcm" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="kkU6FSAOVXN3YEaH" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="kr0EA0kvMm3ee3Va" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <p  className="textMenu" style={{position: "absolute", zIndex: 24, width: "100%", fontFamily: "CC-Maladroit", textAlign: "center"}}>Roadmap</p>
                                        <div id="j4EvbS5jHW1PZjNT" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href="#umv2c0gWqSgvva8E"><img src={flanMenu}  alt="flanMenu" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="OlgyBFomIf2DjwUe" style={{ zIndex: 16 }}>
                        <div id="QnQDFXiD2KbbXk8e" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="A46Tu23TaEF3iAOf" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="OAoUqon1XLrpsHfl" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="mPuoUSGF6Z7gGfJb" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="mWZ7CelML67FguLB" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tmjz9BJ5bLaDbU7g" style={{ zIndex: 18 }}>
                        <div id="WGHxrhcFFHxUJHC3" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="FUukbMYqEBBfWSVo" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="DUbHOUfcPNnT6po0" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="v1t7BOLRKxRWermM" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="MqkROzeKkMpgDu9e" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="QyyoQJQaQngTUbD3" style={{ zIndex: 20 }}>
                        <div id="VGyFfBTVO0X73fSo" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                            <div id="mOep9qBJcQWbtbpu" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="Duj6wZnEBkqqtSvE" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="MAoocCdli7JJ4wRC" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="PgDcEEJukV0UDDQq" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="YG6neC26KAhQ63pB" style={{ zIndex: 11 }}>
                <div id="lT2G8wmdEOxudAiK" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="XooqSMTgWqfhbvSV" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="qzBs2Spvr1JnHgvS" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="cTDNbaKqKHciSVGx" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="RgF4GpnsayxcZpFM" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="UY7a2P3fGjPmXgH3" style={{ zIndex: 13 }}>
                <div id="O4iS6pk9bnjl9AZN" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="Rg3bNdEfkTOSnPgg" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="OIHsXWIs2jtwbGzn" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="jkECM20M58ISCMfD" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="nOrI0RvnTOdeSltD" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Fa7veoUq8pgkccUd" style={{ zIndex: 15 }}>
                <div id="ax5JqEXVxQrIWKlQ" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="M2uGJKJihwRe61ia" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="c6PfZ2YPibMmS4ie" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="xGgeRaid2oyyKXJS" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="pADqFL3KX9TbBKPX" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Gp1vsiFywvjWDBYx" style={{ zIndex: 17 }}>
                <div id="qXvmmja65xF6GkR5" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="zmcrCJ0I25CXBz5I" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="SHuIrc9SrICPbMjU" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="tq4C7LTCrehhBqlS" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="v3W1Prupdf5S2npm" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="J5zDruVX6zF4Q1Sj" style={{ zIndex: 19 }}>
                <div id="KAtwPvnTSyjKwM7l" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="Xm15JAzohVBUZrlM" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="tR3bQHrMsLKgufJl" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="XanGA4Xj44cOjdxu" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="rJIlLC4ij8Onpe8g" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="yx3CLQiKhnMyN7et" style={{ zIndex: 21 }}>
                <div id="Zp7PbDsQFsyetAwx" style={{ paddingTop: "40.64327485%", transform: "rotate(0deg)" }}>
                    <div id="ZRxOtLhTCCy8phND" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="H1eBghAvS5VwAk7F" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="EcjjbUhfa8Fno6u9" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="JECCpjvxKqQeO6Ub" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="yx3CLQiKhnMyN7et" style={{ zIndex: 21 }}>
                <div id="Zp7PbDsQFsyetAwx" style={{ paddingTop: "40.64327485%", paddingRight:"50%", transform: "rotate(0deg)" }}>
                    <div id="ZRxOtLhTCCy8phND" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="H1eBghAvS5VwAk7F" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="EcjjbUhfa8Fno6u9" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="JECCpjvxKqQeO6Ub" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={cloud} alt="Flat Organic Cloud " loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*END MenuSection*/}
            {/*OurPartnersSection*/}
            <div id="mtf5szLDvC85MbKY">
                <div id="mixNEo8r11wYpZMq" style={{ display: "grid", position: "relative" }}>
                    <div id="rCBBtShs5q1KObB4" style={{ zIndex: 7 }}>
                        <div id="knFxP3sF0AqnUJQ4" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="u92SrqTw0W5z8lMj" viewBox="0 0 213.3333 64.3959" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: "0.3", overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="RCJtJA9OOXo7Ru1q" style={{ transform: "scale(1, 1)" }}>
                                    <path id="fg1uKAj2ngxJWT87" d="M213.33333333,0 L213.33333333,55.39589154 L106.66666667,64.39589154 L0,55.39589154 L0,0 L213.33333333,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                                </g>
                            </svg> </div>
                    </div>
                    <div id="ZZA4KEQomKeZgLGl" style={{ zIndex: 79 }}>
                        <div id="fx9V0oRRlU3XjcEK" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="TeZP65WVvDcrv82D" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="aNyV2ZZQXfaJ8h9Z" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center" }}> <span id="bChaXFCokewsjXBS" style={{ color: "#fefefe" }}> our partners </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="FQhizaBIjAnWjO8Q" style={{ zIndex: 45 }}>
                        <div id="dix9u8SKBWZsPagW" style={{ paddingTop: "19.09090909%", transform: "rotate(0deg)" }}>
                            <div id="puIMxwsoVmtLp1wm" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="QypJaQXUW6pcv3TR" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="ecrP4uuiW8PwVeqe" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="oGzt64J2T63B0S88" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorDexscreener} target="blank_" rel="noreferrer"><img src={dexScreener}  alt="dexscreener" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mB4ve4ZijQKjMC4U" style={{ zIndex: 44 }}>
                        <div id="W5HvfLREhJ20vVpW" style={{ paddingTop: "26.72727273%", transform: "rotate(0deg)" }}>
                            <div id="YswcImbHd5MksrO7" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="TaIviZZqi73seDkb" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="D8pBD6fGynsFcSxH" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="BqdInl12rI2nsDBt" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                           <a href={linkSponsorDextools} target="blank_" rel="noreferrer"> <img src={dexTools}  alt="dextools" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*END OurPartnersSection*/}
            <div id="B3touXT8hQVYufku" style={{ zIndex: 80 }}>
                <div id="bQAkbEgVY8Q5n0DW" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="voqKgZwF1N2wIYCx" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                        <p id="AzmZIT5CcyyekhsM" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center" }}> <span id="XBdOnMgbIDfSir6H" style={{ color: "#fefefe" }}> about flan </span> </p>
                    </div>
                </div>
            </div>
            <div id="OsBtOc9CXK78lO25" style={{ zIndex: 56 }}>
                <div id="Lb0k7qZWq3likXwE" style={{ paddingTop: "56.22254758%", transform: "rotate(0deg)" }}>
                    <div id="JoJfxhlA8c8spZA8" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="zLnevelDGluLbedj" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="mUmOFDnXAUQxVEsK" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="trSN6mIIHHWtoxyU" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={thinking} alt="thinking" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="vrzlyNcS4F2YNFME" style={{ zIndex: 54 }}>
                <div id="oZM3TkpGhqGPKmuT" style={{ paddingTop: "100%", transform: "rotate(-14.65179813deg)" }}>
                    <div id="yEuTq70ucwN3tFjE" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="u8X5g9HFGtShnHCR" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="xV11q1XebMwfn97T" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="ip67BA7S5qI1AiiN" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={flanLogo}  alt="flanLogo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="xdj93AtYZ3sSwe1J">
                <div id="yIVu53vWimfS3zjT" style={{ display: "grid", position: "relative" }}>
                    <div id="ZRKGMpzIrMCWzn78" style={{ zIndex: 55 }}>
                        <div id="ivejZUhj9KIVx5yg" style={{ paddingTop: "57.84574468%", transform: "rotate(0deg)" }}>
                            <div id="EC93jQqMTsk6FSU4" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="Qo6gckXGytpWeNtn" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="YNVOPJsQUpB97RFT" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="fpD0EYecwxDIeiKo" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={thinkingText}  alt="thinkingText" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ieWdl4aoVkvXLlwP" style={{ zIndex: 91 }}>
                        <div id="CIWYBBOWu78fCitV" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="fB6LnoHpWSVvBi0K" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="TclBhN74Dx4cT0MH" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.35378298em" }}> 
                                    <span id="RiQ1Rbvgts3e8r7t" style={{ color: "#2b384c" }}> WELCOME TO $FLAN, THE DELICIOUS ADDITION TO THE SOLANA BLOCKCHAIN! OUR SMALL YET MIGHTY FLAN, WITH A TOUCH OF CULINARY ART, BRINGS A WAVE OF CREATIVITY TO THE DEGEN WORLD OF SOLANA. AS THE NEWEST AND TASTIEST TOKEN, $FLAN IS HERE TO ADD FLAVOR. JOIN US IN BRINGING WARMTH AND GATHERING LIQUIDITY TO THE SOLANA KITCHEN. </span> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="R1thD4hog2ldIbGL" style={{ zIndex: 81 }}>
                <div id="iAHDRlGB8TMOoSgM" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="Hf49Cbu3atW2FJwO" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                        <p id="X0VQ1Yn9ATc1O9DL" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center" }}> 
                            <span id="kqn3OuGpovvxquN7" style={{ color: "#fefefe" }}> AVAILABLE ON </span> 
                        </p>
                    </div>
                </div>
            </div>
            <div id="js6x7JtKiw0pFTYN" style={{ zIndex: 52 }}>
                <div id="uIwhQkXeWDndF53P" style={{ paddingTop: "33.9031339%", transform: "rotate(0deg)" }}>
                    <div id="v4kJSq0OKkeECSjk" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="rTymMkTyYNwGD10S" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="NKONtXFmCEYgkmkI" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="szBKC6ffhJFSMSNw" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <a href={linkSponsorRaydium}  target="blank_" rel="noreferrer"><img src={raydium}  alt="raydiun" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /></a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="iS15wFISy6DAd5tP" style={{ zIndex: 53 }}>
                <div id="UbCoBxxm48VcUZXv" style={{ paddingTop: "33.9031339%", transform: "rotate(0deg)" }}>
                    <div id="WnkHbECKypOvnoc0" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="If3N2IH2KpGD99mh" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="jF2CWku41t6Wf7jE" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="glTq8UYSXHN2SNBM" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <a href={linkSponsorJupiter} target="blank_" rel="noreferrer"><img src={jupiter}   alt="jupiter" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="rgBG2rK7XN3kxdwX" style={{ zIndex: 82 }}>
                <div id="lWqqIemFgtwVzXKb" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="ka3iDZ8q3A6uZGGa" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                        <p id="HF131vG2fhLXjui9" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center" }}> 
                            <span id="yIXtxCEF34LYEjbc" style={{ color: "#fefefe" }}> partners </span> 
                        </p>
                    </div>
                </div>
            </div>
            <div id="rrUZDc95aul14TYm">
                <div id="QaHFA2HjmVkwCeug" style={{ display: "grid", position: "relative" }}>
                    <div id="AySwqo6CvDxhxP7U" style={{ zIndex: 26 }}>
                        <div id="e2uJAkgqyaZWfMiG" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="MO2TYza4XEm06Zay" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                <div id="XutZUjC5FNHO8oHi" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                    <div id="x68zu0KP95tLTtzm" style={{ width: "calc(100.237336% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                        <img src={buttonBackground} alt="3D Neomorphic Dark Oval Debossed" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "49.88161298% 50%", transform:"translate(-49.88161298%, -50%) rotate(0deg)" }} /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="pDhxwN7xbXok90ii" style={{ zIndex: 49 }}>
                        <div id="zEpTWKNQi5uHxybH" style={{ paddingTop: "14.875%", transform: "rotate(0deg)" }}>
                            <div id="NaVucV2VwQCwWi45" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="Cy40DpxX3qqVXaDs" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="VdMLIlpNqu0sogZF" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="KqmUb1WohubfelvP" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorSolana} target="blank_" rel="noreferrer"><img src={solana}  alt="solana" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Qark2lDv1mEPk7xJ">
                <div id="qT0KdA1KlHbBiZ1Q" style={{ display: "grid", position: "relative" }}>
                    <div id="uAcKqDQs5nfEXbGS" style={{ zIndex: 27 }}>
                        <div id="h02ZQCpljeQQ7xyg" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="xz8jEn26wrAAP2vB" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                <div id="IZS7VhjPbS9vW5qw" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                    <div id="QF5TfDCx3AtOdcDX" style={{ width: "calc(100.237336% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                        <img src={buttonBackground} alt="3D Neomorphic Dark Oval Debossed" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "49.88161298% 50%", transform: "translate(-49.88161298%, -50%) rotate(0deg)" }} /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="wgZUXMnqLU0gS876" style={{ zIndex: 48 }}>
                        <div id="ESHhxYpDGOy8QnSg" style={{ paddingTop: "105.29220324%", transform: "rotate(0deg)" }}>
                            <div id="vmdvVApC2IaCirSL" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="K5dH5NiTai5iV0NG" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="PHKLZx6rdOlWyoTG" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="V8TyUs60dZlXGWYi" style={{ width: "calc(582.15906572% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorCoinhunters} target="blank_" rel="noreferrer"><img src={coinHunters}  alt="coinhunters" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "9.22983342% 50%", transform: "translate(-9.22983342%, -50%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="BRWF7hDSE8RC8eA5" style={{ zIndex: 47 }}>
                        <div id="X1Eg3Vc3sEpLz90b" style={{ paddingTop: "22.26897825%", transform: "rotate(0deg)" }}>
                            <div id="byRbMjxEHX71PQFo" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="t75NoZetunSZKQ9a" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="yHZORl2txsc1V1qV" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="Rh30uzvY2wQSpzlH" style={{ width: "calc(131.92159658% * max(1, var(--scale-fill, 1)))", height: "calc(107.14456747% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorCoinhunters} target="blank_" rel="noreferrer"><img src={coinHunters}  alt="coinhunters" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "59.93976818% 46.66592174%", transform: "translate(-59.93976818%, -46.66592174%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Oo6N7PEmshM55dlw">
                <div id="nAVRr31EIkrty5J8" style={{ display: "grid", position: "relative" }}>
                    <div id="GjI6ftKYsN7ineSx" style={{ zIndex: 28 }}>
                        <div id="oc21pSh8vhd3hnzJ" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="mI4YwI4LVqiCpHwL" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                <div id="h0CvLvzRgmYlNh7C" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                    <div id="pj5bfa74qbW3JDKo" style={{ width: "calc(100.237336% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                        <img src={buttonBackground} alt="3D Neomorphic Dark Oval Debossed" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "49.88161298% 50%", transform:"translate(-49.88161298%, -50%) rotate(0deg)" }} /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="BhStKjqRFyPgVwND" style={{ zIndex: 50 }}>
                        <div id="p1gNCe8A0KUxFvYK" style={{ paddingTop: "27.98584205%", transform: "rotate(0deg)" }}>
                            <div id="SkmS7gDCrMY1DYoy" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="puKhmlm2Vw4iDT29" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="KceTG5n5XMEDZtKR" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="qoavF1RouRAfnLti" style={{ width: "calc(132.33409027% * max(1, var(--scale-fill, 1)))", height: "calc(150.98363946% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorCoinhunt} target="blank_" rel="noreferrer"><img src={coinHunt}  alt="coinhunt" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50.77016527% 46.51029697%", transform: "translate(-50.77016527%, -46.51029697%) rotate(0deg)" }} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="x5UJu0vsORDSyB3Q">
                <div id="xC4tcxjk3O1dx1a7" style={{ display: "grid", position: "relative" }}>
                    <div id="pOGfOfE70ztwAW0M" style={{ zIndex: 29 }}>
                        <div id="he3eUqY8p8zJ2vTY" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="SEH2c3GtQJ1CgtBr" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                <div id="QGgX8v8GrKxPI7tJ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                    <div id="ksNULu4xgXIuCuly" style={{ width: "calc(100.237336% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                        <img src={buttonBackground} alt="3D Neomorphic Dark Oval Debossed" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "49.88161298% 50%", transform:"translate(-49.88161298%, -50%) rotate(0deg)" }} /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="orm3CFReH4r98D5b" style={{ zIndex: 46 }}>
                        <div id="tMLYkDm9A0qAAAmO" style={{ paddingTop: "40.97455913%", transform: "rotate(0deg)" }}>
                            <div id="WKzam8JntPcBjZ1b" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="nJBUdbfrTDa1kqBs" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="HCkKzlysY0cfwth9" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="LO4Ke8uJWNNR557p" style={{ width: "calc(194.93673823% * max(1, var(--scale-fill, 1)))", height: "calc(475.75066672% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <a href={linkSponsorGottasneakers} target="blank_" rel="noreferrer"><img src={gottaSneakers}  alt="gottasneakers" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50.18827829% 60.65520714%", transform: "translate(-50.18827829%, -60.65520714%) rotate(0deg)" }} /> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="dacJOe4njronuV3A">
                <div id="NFQR6whSAWEVv5zD" style={{ display: "grid", position: "relative" }}>
                    <div id="SbK8phczSz3Yy2kR" style={{ zIndex: 30 }}>
                        <div id="blleLEO56MQXcvMs" style={{ paddingTop: "10.18181818%", transform: "rotate(0deg)" }}>
                            <div id="WFscbAVjGsFD095m" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="F9uXi4rUXjDNsz70" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="UXZqh4axNVr0cvaK" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="C39hDSJxdw7QsbYG" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={search} alt="Search Bar" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="eG8jG7lCD9BvzIzK" style={{ zIndex: 83 }}>
                        <div id="MrfyyJM9OvZRfZQG" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="nHai9mCCcYmkGmvk" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="f0oo5QSFdRQnb2xx" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center" }}> <span id="ijfvyyjQK1uz0C3K" style={{ color: "#2b384c" }}> flan tokenomics </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="N3dL3hr62TgxB9mO">
                <div id="WgOZBDHZODP9qF5j" style={{ display: "grid", position: "relative" }}>
                    <div id="kQF7lSBJ2g6NT3ZC" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>
                        <div id="ty3dBjTVXFjFfBvV" style={{ zIndex: 32 }}>
                            <div id="d20p8peeFSYusbQQ" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="FHC3udv0pM1u8BvN" viewBox="0 0 105.7986 10.033" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="bge3dce92zsYMLy8" style={{ transform: "scale(1, 1)" }}>
                                        <path id="Z3uNgs0AR7aAZp01" d="M5.01650667,0 L100.78205442,0 C102.11251472,0 103.38848282,0.52852329 104.32926031,1.46930078 105.27003781,2.41007828 105.7985611,3.68604638 105.7985611,5.01650667 L105.7985611,5.01650667 C105.7985611,6.34696696 105.2700378,7.62293506 104.32926031,8.56371256 103.38848281,9.50449005 102.11251471,10.03301334 100.78205442,10.03301334 L5.01650667,10.03301334 C3.68604638,10.03301334 2.41007828,9.50449005 1.46930079,8.56371256 0.52852329,7.62293506 0,6.34696696 0,5.01650667 L0,5.01650667 C0,3.68604638 0.52852329,2.41007828 1.46930079,1.46930079 2.41007828,0.52852329 3.68604638,0 5.01650667,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                    </g>
                                </svg> <svg id="hXHL65Bob86sQMKk" viewBox="0 0 69.9658 10.2459" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="cVyqxdWwCI3XcvN8" style={{ transform: "scale(1, 1)" }}>
                                        <path id="HRgO5IFdCwRJOMoC" d="M5.12294483,0 L64.84289074,0 C66.20158018,0 67.50462125,0.53973728 68.46535977,1.5004758 69.42609829,2.46121432 69.96583557,3.76425538 69.96583557,5.12294483 L69.96583557,5.12294483 C69.96583557,7.95226914 67.67221504,10.24588966 64.84289074,10.24588966 L5.12294483,10.24588966 C3.76425539,10.24588966 2.46121432,9.70615238 1.5004758,8.74541386 0.53973728,7.78467534 0,6.48163428 0,5.12294483 L0,5.12294483 C0,3.76425539 0.53973728,2.46121432 1.5004758,1.5004758 2.46121432,0.53973728 3.76425538,0 5.12294483,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                    </g>
                                </svg> <svg id="CxN9jFP7QLQUwkIL" viewBox="0 0 53.5676 8.8682" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="OZoHai0XLveNw5eK" style={{ transform: "scale(1, 1)" }}>
                                        <path id="ytgvOuO00U97CWEL" d="M4.43410158,0 L49.13349104,0 C50.3094879,0 51.43731898,0.46716293 52.26887434,1.29871828 53.10042969,2.13027364 53.56759262,3.25810472 53.56759262,4.43410158 L53.56759262,4.43410158 C53.56759262,6.88298826 51.58237772,8.86820316 49.13349104,8.86820316 L4.43410158,8.86820316 C3.25810472,8.86820316 2.13027364,8.40104023 1.29871828,7.56948488 0.46716293,6.73792953 0,5.61009844 0,4.43410158 L0,4.43410158 C0,3.25810472 0.46716293,2.13027364 1.29871828,1.29871828 2.13027364,0.46716293 3.25810472,0 4.43410158,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="hKNlc7Ugk2taaLmi" style={{ display: "grid", position: "relative", gridArea: "3 / 3 / 4 / 4" }}>
                            <div id="CDy0Ap5Xy2HKSq5W" style={{ zIndex: 33 }}>
                                <div id="E5a3mOH4rbKZjaYh" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                    <div id="ETfuji8X6fdNh0rk" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "center", width: "100%", height: "100%" }}>
                                        <p id="eUl8ijuweGyv8Go3" style={{ color: "#ffffff", fontFamily: "CC-Maladroit", lineHeight: "1.39656858em", textAlign: "center", textTransform: "none", letterSpacing: "0em" }}> <span id="CiUCcShJW0Gp0rzq" style={{ color: "#ffffff" }}> ss </span> <br /> </p>
                                    </div>
                                </div>
                            </div>
                            <div id="TD6wpXhysu3e8a2x" style={{ zIndex: 84 }} onClick={copyToClipboard}>
                                <div id="KGwCQKregihMYjgU" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                    <div id="B4sxpiFf5maRh5QN" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                        <p id="KwlTrWEohkZjSvTP" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.37499141em", textAlign: "center" }}> 
                                            <span id="ZUF9Vy2Wg74vGuoV" style={{ color: "#2b384c" }}> {caCode} </span> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div id="yAqTd7HTXECXkgQq" style={{ display: "grid", position: "relative", gridArea: "3 / 5 / 6 / 6" }}>
                                <div id="YPEvfkXXCYVCdBC3" style={{ zIndex: 34 }}>
                                    <div id="KsEsOCBUpefm2cd0" style={{ paddingTop: "49.35405736%", transform: "rotate(0deg)" }}>
                                        <div id="NusbaUSr7I6uzdvH" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> <svg id="Aes6ECtLYZCAqw3a" viewBox="0 0 16.4737 8.1305" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                                <g id="vggGmAwVFm9w2U2w" style={{ transform: "scale(1, 1)" }}>
                                                    <path id="gYbIDikiosXvNybw" d="M4.06522512,0 L12.40849733,0 C13.48666207,0 14.52066799,0.42829927 15.28304559,1.19067687 16.04542319,1.95305447 16.47372246,2.98706038 16.47372246,4.06522512 L16.47372246,4.06522512 C16.47372246,5.14338986 16.04542318,6.17739578 15.28304559,6.93977338 14.52066799,7.70215098 13.48666207,8.13045025 12.40849733,8.13045025 L4.06522512,8.13045025 C2.98706039,8.13045025 1.95305447,7.70215098 1.19067687,6.93977338 0.42829927,6.17739578 0,5.14338986 0,4.06522512 L0,4.06522512 C0,2.98706039 0.42829927,1.95305447 1.19067687,1.19067687 1.95305447,0.42829927 2.98706038,0 4.06522512,0 Z" style={{ fill: "#2b384c", opacity: 1.0 }} />
                                                </g>
                                            </svg> </div>
                                    </div>
                                </div>
                                <div id="i4vtf3scyA2mQe1B" style={{ zIndex: 85 }}>
                                    <div id="vmjgU83GU6T33uSt" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                        <div id="WXZU5d7oqRyMNHBm" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                            <p id="wOngQWwnITO3ek6F" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39799871em", textAlign: "center" }}> <span id="mLrDP5LLBvPU6CCP" style={{ color: "#fefefe" }}> copy </span> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="GhUu3mi7Ec9HB9rc">
                <div id="QHFlB8OOO9oH8Emk" style={{ display: "grid", position: "relative" }}>
                    <div id="Gc5ilQDHMxJqjcX1" style={{ zIndex: 35 }}>
                        <div id="YXuqI14G6LS9Rlwh" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="tqdLTc3SllwUdxuV" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="fweSZmW2Ob0Ohn3d" style={{ transform: "scale(1, 1)" }}>
                                    <g id="uweYvS5qq9QvYDHq" style={{ clipPath: "url(#kQ1Lhq34RTV9rSoe)" }}>
                                        <clipPath id="kQ1Lhq34RTV9rSoe">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="DQz9cdMlb9OCsZ2v" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="hHeEneLwX6iYGRiw" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="i3yxhTaEpV7YfIzh" style={{ display: "grid", position: "relative" }}>
                        <div id="vtxOqDGtkOomh6c4" style={{ zIndex: 36 }}>
                            <div id="JFsq3uquhfnOwlW0" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="Nve9R8AXy0Vx8BiT" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="fQD9hrQ82Jcc2dRg" style={{ transform: "scale(1, 1)" }}>
                                        <g id="nQmCOHHdbH0rFzFp" style={{ clipPath: "url(#sDKZAL669hWtmQeJ)" }}>
                                            <clipPath id="sDKZAL669hWtmQeJ">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="Qh0QjhGJUPY5UDXo" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="JUCRp22OlinkRVbF" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="kVnbVY6vJ9aOimoK" style={{ zIndex: 60 }}>
                            <div id="TzmbwbBGP7AdpKE6" style={{ paddingTop: "75.71009123%", transform: "rotate(0deg)" }}>
                                <div id="LJbz9KNidJB1RYOi" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="Bhfjwy0rXADkRzhf" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="g1ftPLfAdJUULpQ9" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="vcPReIgesJ8P96Y4" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(132.08278893% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={revoked}  alt="revoked" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 62.14495438%", transform: "translate(-50%, -62.14495438%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="EOE83zjBcD9JjTpM" style={{ zIndex: 87 }}>
                        <div id="OwSjd0oWZFTZfPig" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="kNea6pFoLtjSJyho" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="sQVW8uEg616apV5E" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="sTIaeY4affDXX113" style={{ color: "#2b384c" }}> MINT &amp; FREEZE </span> </p>
                                <p id="XYYVCQOVwa1I0S82" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="aQgXSJ6UsQzOkVah" style={{ color: "#2b384c" }}> REVOKED </span> <br /> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Mslk2lbg3C9rMtMA">
                <div id="MAHebwnIHDhjGrTv" style={{ display: "grid", position: "relative" }}>
                    <div id="cJXUE9zgMy6yTzXu" style={{ zIndex: 37 }}>
                        <div id="S07TKjQKXeBku9qi" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="Wf2p9Y18F3NloEHn" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="XAqxQqjBdSVnUauK" style={{ transform: "scale(1, 1)" }}>
                                    <g id="uG0kwgs0oWyqT9Wz" style={{ clipPath: "url(#kHIx3Bza37ZcgEkD)" }}>
                                        <clipPath id="kHIx3Bza37ZcgEkD">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="aFev8p3vfXLEkoGl" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="YI7T4cmBzpXvgTDf" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="CrJtSJ6WRogo1Dwm" style={{ display: "grid", position: "relative" }}>
                        <div id="vVMH4QJHgQSI8tYw" style={{ zIndex: 38 }}>
                            <div id="QOPJEdxc8lIJJIr2" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="RgMcxhbKmbabWOeg" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="T7CPJblTDUmmmXNf" style={{ transform: "scale(1, 1)" }}>
                                        <g id="svRltJLc6gZKeNIw" style={{ clipPath: "url(#P5wI53hEaySulepr)" }}>
                                            <clipPath id="P5wI53hEaySulepr">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="A1iQB39syyZJ2Rsd" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="XO68nZwnrTo8yNuj" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="flnvi34U4N5RP4MF" style={{ zIndex: 59 }}>
                            <div id="u8VI9issB3yx2rWP" style={{ paddingTop: "108.80342831%", transform: "rotate(0deg)" }}>
                                <div id="bmZeUaBXp8upa1Yu" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="aZ6MDUbgubHPoyfp" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="GVsNdXj9l1H7OJaX" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="hKUh5D4CNVPmWMAF" style={{ width: "calc(162.38744879% * max(1, var(--scale-fill, 1)))", height: "calc(149.248467% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={burned}  alt="burned" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "51.5294358% 66.07819573%", transform: "translate(-51.5294358%, -66.07819573%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="KcbxVVPaP59dSprq" style={{ zIndex: 88 }}>
                        <div id="IArMZJnQrW8dLywi" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="hittd1k4zaviwWil" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="cNzaglVmE7L9wS6K" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="vQxupMwGSq5opo4D" style={{ color: "#2b384c" }}> LIQUIDITY </span> </p>
                                <p id="xub0z6mA0sutUvFS" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="TvCZ4HALZX0hy0EW" style={{ color: "#2b384c" }}> BURNED </span> <br /> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mMbrafSKwCAf6LwA">
                <div id="RqWagWZfujpRnOm3" style={{ display: "grid", position: "relative" }}>
                    <div id="xWtMEwmJ3hBF5doS" style={{ zIndex: 39 }}>
                        <div id="B5YHiVAPKwPK4loB" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="HJy9dYcT5WQ1rpHi" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="Nfvf54HfxmZyCHuv" style={{ transform: "scale(1, 1)" }}>
                                    <g id="vF8tcPLhSXZq8lXn" style={{ clipPath: "url(#jJ0gfdG8YwvqwZ1R)" }}>
                                        <clipPath id="jJ0gfdG8YwvqwZ1R">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="YXs2tePEW5LfLwul" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="JStc1BzaVcSKcLys" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="ux4gV6sHmTWIZL36" style={{ display: "grid", position: "relative" }}>
                        <div id="VXgWi0EmYkb38grf" style={{ zIndex: 40 }}>
                            <div id="qdgiOYx69I5D8csT" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="ckiwvl5Ii0QhHtsZ" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="pVvAcg6DKWEeuEmC" style={{ transform: "scale(1, 1)" }}>
                                        <g id="eZyUom3Zz3yKew9X" style={{ clipPath: "url(#HHJ5oQlcJyQzkjr4)" }}>
                                            <clipPath id="HHJ5oQlcJyQzkjr4">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="dHszyhr7KlgPBSmH" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="eVg8QT35llW1IA8c" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="FYvWvJU74vhQl7DY" style={{ zIndex: 61 }}>
                            <div id="SdpY1fU6KrHzQR0g" style={{ paddingTop: "93.05009585%", transform: "rotate(0deg)" }}>
                                <div id="zByyiDjzKmglVT6L" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="OW5i6QGOQ6u6NlT5" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="V5tvjcI9f3dxNZzJ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="a77xZaGXRXkwzYdx" style={{ width: "calc(155.58127099% * max(1, var(--scale-fill, 1)))", height: "calc(167.20162357% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={tax}  alt="tax" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "48.22927566% 70.09598416%", transform: "translate(-48.22927566%, -70.09598416%) rotate(0deg)"
                                                }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="iY1T5roUgNdVtIf1" style={{ zIndex: 86 }}>
                        <div id="t5e0G0ZAC3BNKbrc" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="kGl5EiBM7g3dDmhv" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="gQmA8mE4UW8B0gXZ" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em", textAlign: "center" }}> <span id="Q8O1i4aDDSoVhCzM" style={{ color: "#2b384c" }}> taxes{" "} </span> </p>
                                <p id="zW4cWezdEZ4LUD2B" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em", textAlign: "center" }}> <span id="RusD2wv4rsuB5xBa" style={{ color: "#2b384c" }}> 0/0 </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="Qph6e4ZbW2ZKdDGv">
                <div id="JceAc3rAb9nIIXbq" style={{ display: "grid", position: "relative" }}>
                    <div id="KSjhkuCtSaBfXtIy" style={{ zIndex: 41 }}>
                        <div id="IkJXwrvkq03u1uTp" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="QMmhjvZs0uQeykHC" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="uC31EApPoMXJJU6X" style={{ transform: "scale(1, 1)" }}>
                                    <g id="JXVT7UOCuLIy6LWH" style={{ clipPath: "url(#C4UuQVECl2lrfU5I)" }}>
                                        <clipPath id="C4UuQVECl2lrfU5I">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="aLu8leHa0L4qPDd5" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="L5UveH5cit6iX3JS" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="bQWEy31fOyBFe0Mb" style={{ display: "grid", position: "relative", gridArea: "3 / 4 / 4 / 5" }}>
                        <div id="AGFvyX4dVxhrH4SY" style={{ zIndex: 57 }}>
                            <div id="e28xEf0zxhyzpFKw" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="nsS0mEuQ4sRhgdiz" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="mp5hEiVssoMqDYZQ" style={{ transform: "scale(1, 1)" }}>
                                        <g id="HeUtbfV41ZOAJpRy" style={{ clipPath: "url(#S5ugW9OOU5sneXcD)" }}>
                                            <clipPath id="S5ugW9OOU5sneXcD">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="vUSAGd0P7aHGq2Yt" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="LrzshFUjiEE0mhFe" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="tqp04ENfzfxJDrQl" style={{ zIndex: 58 }}>
                            <div id="VRSLvz7mVgAAw9VR" style={{ paddingTop: "82.71407391%", transform: "rotate(0deg)" }}>
                                <div id="BdhmtaDNaeqNLcTX" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="sQZaRw62xVIlTtJd" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="tEgpNsCPLE0QIeQF" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="Ul3rDlm3nQpc7MXe" style={{ width: "calc(105.97724236% * max(1, var(--scale-fill, 1)))", height: "calc(128.12480073% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={supply}  alt="supply" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50.85398222% 60.97554906%", transform: "translate(-50.85398222%, -60.97554906%) rotate(0deg)"
                                                }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="EogTUumdo9MFziKL" style={{ zIndex: 89 }}>
                        <div id="FzAAQxk9eqB7NTGz" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="Hnowi7zvzCmwTRfT" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="qeryeBcCwKeR60RG" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="Gf0azLkPRAnMiiZa" style={{ color: "#2b384c" }}> TOTAL SUPPLY </span> </p>
                                <p id="vP6HSabydYuFVlEu" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="NMqEWNcCYUdOFCjw" style={{ color: "#2b384c" }}> 497.5 </span> <span id="ISPHtTK3bDFZ49F8" style={{ color: "#2b384c" }}> {" "} M </span> <br /> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ptwRldzzbUXkMuqv">
                <div id="t1xAIblS8SChH8gA" style={{ display: "grid", position: "relative" }}>
                    <div id="qFseDs1BHLZtdM7W" style={{ zIndex: 62 }}>
                        <div id="usx01MAM0rUh5ZNm" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="Oh1yBt4pinfPMb3W" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="kDU71IV1ejJ38vgq" style={{ transform: "scale(1, 1)" }}>
                                    <g id="pHg9uzLE1RHMNUCk" style={{ clipPath: "url(#hWjajL1rxLnL58ab)" }}>
                                        <clipPath id="hWjajL1rxLnL58ab">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="pItdN22YrgAJxUJK" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="JzH4S4ZV1zVYFgMc" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="JRzaaEVJtOIEbDyi" style={{ display: "grid", position: "relative" }}>
                        <div id="BT1atUD2ZEoKZHAr" style={{ zIndex: 65 }}>
                            <div id="UM3pLXV4FJ0xpwtE" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="WhNmnhyX0lgIGIxz" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="nv2Vq6vk2KiPxaQc" style={{ transform: "scale(1, 1)" }}>
                                        <g id="aRgrWSRQ1hpyQ4BJ" style={{ clipPath: "url(#CPU3E5WEPW29pMVH)" }}>
                                            <clipPath id="CPU3E5WEPW29pMVH">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="EMDIzORDqAuWgDD9" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="t1agc1NPCvz5VViy" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="mGi0KZLLncffw5PP" style={{ zIndex: 73 }}>
                            <div id="c4BCyKEeM8FVvyS2" style={{ paddingTop: "73.69249667%", transform: "rotate(0deg)" }}>
                                <div id="wZhAqTdaXf1n0C4v" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="JeZnGQ3ITK5W4a3A" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="HcCB1dhuUAc9hgRn" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="rTLQEcu50S4MH4nX" style={{ width: "calc(104.58595176% * max(1, var(--scale-fill, 1)))", height: "calc(141.92211756% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={team}  alt="team" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "48.766757% 64.76940955%", transform: "translate(-48.766757%, -64.76940955%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="YMilH76jnxKOJWA8" style={{ zIndex: 92 }}>
                        <div id="xi1ZfJzC8SmgTW0c" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="ekWwULduLcZIN9m9" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="iNmf1O0lzYT4rbyi" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="FiBL6huwmIGzmnkj" style={{ color: "#2b384c" }}> team </span> </p>
                                <p id="bpwmEmdHFhvPEJyT" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="p6PApoxhHDX03spq" style={{ color: "#2b384c" }}> 29.8 m </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="GOnvaU8V9Q4ApFlk">
                <div id="F6FuXeAgdelufaUD" style={{ display: "grid", position: "relative" }}>
                    <div id="of9ee433T1no1eb5" style={{ zIndex: 63 }}>
                        <div id="icg6VgwVNMorzT41" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="KMZsL5LRM7wuT83O" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="EZVlg2y7vv0teZNB" style={{ transform: "scale(1, 1)" }}>
                                    <g id="biuRNhaatqcA5kvw" style={{ clipPath: "url(#XAOz3TUJYus0cz6o)" }}>
                                        <clipPath id="XAOz3TUJYus0cz6o">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="pea01reshyqQh9uj" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="cnr9iDcASK12Iatt" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="QFUDpxxQ0ntOzGPG" style={{ display: "grid", position: "relative" }}>
                        <div id="KNHCjAj4wkDrSXvp" style={{ zIndex: 66 }}>
                            <div id="YxS4QwIEoHjZaUki" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="Q466zYRwrR5BX8XR" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="gWNmhGgg4XrdNidp" style={{ transform: "scale(1, 1)" }}>
                                        <g id="rklcbxxdzOd4ECyo" style={{ clipPath: "url(#oKP2RyvRjAYP5hrS)" }}>
                                            <clipPath id="oKP2RyvRjAYP5hrS">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="gJ3JVpLQO4UVYs3R" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="bO5qt0DR51hWvp7u" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="OTojbyTMPBUuHmwD" style={{ zIndex: 77 }}>
                            <div id="OZYyBTZxzMSMBFbJ" style={{ paddingTop: "95.13369387%", transform: "rotate(0deg)" }}>
                                <div id="kYWXUaPcVz16iBKi" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="NikbVeBu7AGH6Enr" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="KlT5Z11Smsyj3lNW" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="lfEwqB2TMvlZFBMw" style={{ width: "calc(127.66681417% * max(1, var(--scale-fill, 1)))", height: "calc(134.19726384% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={marketing}  alt="marketing" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "45.74989668% 62.7414162%", transform: "translate(-45.74989668%, -62.7414162%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="O9k5NSmjf3dSyyh1" style={{ zIndex: 93 }}>
                        <div id="GVoEfNcfm25B1zVZ" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="SOc2ohVAB8CYHJSf" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="NVb0mkRJsOHZzJbL" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="qmOw1QcSBQj9DBPZ" style={{ color: "#2b384c" }}> marketing </span> </p>
                                <p id="qkl8O5D8mUyuf68Y" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="FKo72JjvpdUU9lml" style={{ color: "#2b384c" }}> 29.8 M </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mnSqMqXmgOSlFei6">
                <div id="YYDpQSVvhSxW2rX6" style={{ display: "grid", position: "relative" }}>
                    <div id="P92VnPzdEt2uCB7K" style={{ zIndex: 64 }}>
                        <div id="jtnkcDrUfMoy68jI" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="qt68lvHyNf6J6ezB" viewBox="0 0 53.9204 48.9993" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                <g id="NpNbUWWlfGm4ufs2" style={{ transform: "scale(1, 1)" }}>
                                    <g id="sikqWKMUJYPWent3" style={{ clipPath: "url(#AjNQregbai8P09Xi)" }}>
                                        <clipPath id="AjNQregbai8P09Xi">
                                            <path d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" />
                                        </clipPath>
                                        <path id="zQAIahIOdaL3V0UZ" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "#fefefe", opacity: 1.0 }} />
                                        <path id="ODORlrklgfI5JsDA" d="M5.87768116,0 L48.04268322,0 C49.60154118,0 51.09655238,0.61925391 52.19883142,1.72153295 53.30111046,2.82381199 53.92036438,4.31882319 53.92036438,5.87768116 L53.92036438,43.12166271 C53.92036438,44.68052067 53.30111047,46.17553188 52.19883143,47.27781091 51.09655239,48.38008995 49.60154119,48.99934387 48.04268322,48.99934387 L5.87768116,48.99934387 C4.3188232,48.99934387 2.82381199,48.38008996 1.72153296,47.27781092 0.61925392,46.17553188 0,44.68052068 0,43.12166271 L0,5.87768116 C0,4.3188232 0.61925391,2.823812 1.72153295,1.72153296 2.82381199,0.61925392 4.31882319,0 5.87768116,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                    </g>
                                </g>
                            </svg> </div>
                    </div>
                    <div id="WQDgCUZpIYmiqMZR" style={{ display: "grid", position: "relative" }}>
                        <div id="kM9HDpmhHpUJGVKh" style={{ zIndex: 67 }}>
                            <div id="dEOE2S2Eeid8xxP7" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}> <svg id="YQcSlNTvXhHzmq66" viewBox="0 0 42.7972 29.6378" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="GN5Kcs3BIe9u8DPZ" style={{ transform: "scale(1, 1)" }}>
                                        <g id="ROeavVQbY4iFEZR3" style={{ clipPath: "url(#CFFeOrDnqQSUpluY)" }}>
                                            <clipPath id="CFFeOrDnqQSUpluY">
                                                <path d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" />
                                            </clipPath>
                                            <path id="f9quOTrcGdfV9h3d" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "#2596ff", opacity: 1.0 }} />
                                            <path id="SBX5QO3HsZqk5Uvv" d="M5.87767125,0 L36.91957377,0 C38.47842911,0 39.97343779,0.61925287 41.07571498,1.72153005 42.17799216,2.82380723 42.79724503,4.31881591 42.79724503,5.87767125 L42.79724503,23.76017855 C42.79724503,25.31903389 42.17799216,26.81404258 41.07571497,27.91631976 39.97343779,29.01859694 38.47842911,29.63784981 36.91957377,29.63784981 L5.87767125,29.63784981 C4.31881591,29.63784981 2.82380723,29.01859694 1.72153005,27.91631975 0.61925287,26.81404257 0,25.31903389 0,23.76017855 L0,5.87767125 C0,4.31881592 0.61925287,2.82380723 1.72153005,1.72153005 2.82380723,0.61925287 4.31881591,0 5.87767125,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#2b384c" }} />
                                        </g>
                                    </g>
                                </svg> </div>
                        </div>
                        <div id="pATGC1Pe0ql2gBnb" style={{ zIndex: 68 }}>
                            <div id="L6Ub9aAGdowQsAO3" style={{ paddingTop: "93.1182994%", transform: "rotate(0deg)" }}>
                                <div id="NNMIeFe3CKYcplWm" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="gq8zAoL6zyr7ilJC" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="mz0WoYnhkfh6uBNm" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="VfE4cB0JEpqntfhs" style={{ width: "calc(135.14510625% * max(1, var(--scale-fill, 1)))", height: "calc(145.13270444% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={gameRewards}  alt="rewards" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "48.57908289% 65.54877125%", transform: "translate(-48.57908289%, -65.54877125%) rotate(0deg)"
                                                }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CMKKX5uv17NEzpWS" style={{ zIndex: 94 }}>
                        <div id="kj1s7oVA7pcPRiZW" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="SNPX2r9xUIXesNuv" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="viKCM7lAQ1pxmzTL" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="psmgHwowIerbXrku" style={{ color: "#2b384c" }}> game rewards </span> </p>
                                <p id="XWVWVUtRkOwfGm1F" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="HgKhAMibIwAHZjEo" style={{ color: "#2b384c" }}> 64.6 M </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mj7r1oYrDwieHfcy">
                <div id="Pc4N83BdzS4vg8no" style={{ display: "grid", position: "relative" }}>
                    <div id="KBZChASUMTqV28Hb" style={{ zIndex: 43 }}>
                        <div id="MMh8gey0Iw3K7ON0" style={{ paddingTop: "60.73069976%", transform: "rotate(-180deg)" }}>
                            <div id="pCjfIobrBZAFeyHR" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> <svg id="WFgO3TrfBeQFep25" viewBox="0 0 213.3333 129.5588" style={{ width: "100%", height: "100%", opacity: "0.3", overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="IttoiM6sYD0f6Rr4" style={{ transform: "scale(1, 1)" }}>
                                        <path id="gchPqAghKJ7QNk9d" d="M213.33333333,0 L213.33333333,120.55882615 L106.66666667,129.55882615 L0,120.55882615 L0,0 L213.33333333,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                                    </g>
                                </svg> </div>
                        </div>
                    </div>
                    <div id="umv2c0gWqSgvva8E" style={{ zIndex: 90 }}>
                        <div id="PdyeOsZFYNTyWvB3" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="r2YN3bsHDAleQSAb" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="vj8t6QBa5C7JPfDx" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.38283087em", textAlign: "center" }}> <span id="LbpLk6WGvjqULK0k" style={{ color: "#fefefe" }}> roadmap </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="vHDmiwfSuHcsSyeN" style={{ display: "grid", position: "relative", gridArea: "5 / 3 / 8 / 8" }}>
                        <div id="RGKb45JuP8eIvflN" style={{ zIndex: 70 }}>
                            <div id="XwcnFXuxDbBFnGOQ" style={{ paddingTop: "57.84574468%", transform: "rotate(0deg)" }}>
                                <div id="E1moQRhTfxTEhtTL" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="fZy1Yz8jX2UQ04cK" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="qJjRI8tOtmocnb7P" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="XvvNArXjRnCXoElX" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={thinkingText}  alt="thinkingText" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="nD7lBzoQgVsxhJzy" style={{ zIndex: 98 }}>
                            <div id="WMFHhYORdcIv3KDG" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="YhhWrvHSMqQSj48k" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="abihUKZ0pVsn5oOX" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.3879683em", textAlign: "center" }}> <span id="w2xdU8nH7BdFs64K" style={{ color: "#000000" }}> phase 1 q2 </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="NtTi3i8ZqEVJyE9o" style={{ zIndex: 102 }}>
                            <div id="dxkppPfm7cDAciEB" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="hKJF4pqRLxUDXdsF" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="X3yS5uF0c18h6tXB" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.37108384em", textAlign: "center" }}> <span id="ol5e6xRwsrioTReL" style={{ textDecorationLine: "underline", color: "#000000" }}> 2024 </span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="e7NJEEimDsEzY4Xu" style={{ zIndex: 95 }}>
                        <div id="l0NjGHWhKgigyueV" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="OnsYbt5ehgoSL66t" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <ul id="G7r3aMlBiMsCEk7E">
                                    <li id="Ai4NJaMXbveygoZb" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="OdTNZQ7HtnzThoh9" style={{ color: "#2b384c" }}> $FLAN Token Launch </span> </li>
                                    <li id="Q2C4oJvg4xZtK1Nj" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="qxqUultbfgwMDePV" style={{ color: "#2b384c" }}> Develop FlanCraft </span> </li>
                                    {/* <li id="TN0ujXFWpER0mk2B" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="jDE8UaYwbN8MZ3BQ" style={{ color: "#2b384c" }}> negotiations with cex </span> </li> */}
                                </ul>
                                <p id="lEwGVTxF5NRoaBsK" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em", whiteSpace: "pre" }}> <br /> </p>
                                <p id="mrcKs13p9hPNXu5t" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em", whiteSpace: "pre" }}> <br /> </p>
                            </div>
                        </div>
                    </div>
                    <div id="yD3xoV0s2tBiPMhL" style={{ zIndex: 69 }}>
                        <div id="hFNGIDx6kZRaddUr" style={{ paddingTop: "56.22254758%", transform: "rotate(-142.95285695deg)" }}>
                            <div id="d79BogFeZmwqAoe3" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="BVwHWBbGhKMgOSSb" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="F2pcppWXt2E9vKfM" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="yIwmFzyoBbQDn8ny" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={thinking} alt="thinking" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mRz9Whx4xEQ4B8My" style={{ display: "grid", position: "relative", gridArea: "9 / 9 / 12 / 12" }}>
                        <div id="F39Bkum0yYJuGTst" style={{ zIndex: 74 }}>
                            <div id="z8EUtRYEKZ3TUJQv" style={{ paddingTop: "57.84574468%", transform: "rotate(0deg)" }}>
                                <div id="jPuRiuqxwW9zvBwO" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="nMC1NmBFHrMOCjI5" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="glCCXzSPmxWpFJmJ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="iPGaidrM0GpHmgMt" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={thinkingText} alt="thinkingText" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="iUnHaVPeveNC0qCn" style={{ zIndex: 99 }}>
                            <div id="LZgxZQw1GjKLqVOT" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="t9AIfvRbmK8LsdNY" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="HtTr8wxDVw7EFu0D" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.3879683em", textAlign: "center" }}> <span id="ePEncWi2Q6CNZTAH" style={{ color: "#000000" }}> phase 2 q3 </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="Lae4Qx0ku8Axhq4s" style={{ zIndex: 103 }}>
                            <div id="vqmjHKngur34mkVs" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="cxmgyz9C2VTLCDDD" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="TrD8xrLY26sU2rNM" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.37108384em", textAlign: "center" }}> <span id="aTcprX388NJK3zq1" style={{ textDecorationLine: "underline", color: "#000000" }}> 2024 </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="WOsAMN7XC3qVqprl" style={{ zIndex: 96 }}>
                            <div id="CnHk2md6MCQVDA5C" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="lcspUXEW82pUWsLP" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <ul id="JWaQy1yudR1p8Syd">
                                        <li id="cYabuqNhLK1oquUr" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="vQG72ZrhktCBBzIZ" style={{ color: "#2b384c" }}> Launch FlanCraft (Play2Earn Minecraft Server) </span> </li>
                                        <li id="Vk1xp2D70IEzfE3i" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="MFjqhuM8Ml1eWae0" style={{ color: "#2b384c" }}> Develop FlanCraft NFTs </span> </li>
                                    </ul>
                                    {/* <p id="mB3RQmtDMYSckXkz" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="J4YCY6Wkkam34C6T" style={{ color: "#2b384c" }}> {" "} marketplace </span> </p>
                                    <ul id="pmTu4PO6hbGQJct9">
                                        <li id="YunSiQTZkn6nKJjc" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="uf1SCm3VmwLUJhyQ" style={{ color: "#2b384c" }}> development of the game </span> </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="OvdiM4IyOj32z55A" style={{ zIndex: 71 }}>
                <div id="bISoVhHwms3s322Z" style={{ paddingTop: "56.22254758%", transform: "rotate(-25.17592976deg)" }}>
                    <div id="UAdAAMLHLz2QnHR2" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                        <div id="Aufk3Yhcjso4Gr10" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="zfqtzbLoQcn5njJS" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="a5OnjHzijE6OQijM" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={thinking} alt="thinking" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="M4o9BRC8NAwtIkHC">
                <div id="LrFSskNOpE07Ty50" style={{ display: "grid", position: "relative" }}>
                    <div id="HKs40VwbuGKozZvg" style={{ zIndex: 75 }}>
                        <div id="OSRMi1zhYsxZY8Lg" style={{ paddingTop: "57.84574468%", transform: "rotate(0deg)" }}>
                            <div id="yJmKui34kk6LG2Tf" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="X0LZHjasRgppxbg7" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="bRCkJrP161Al4W9c" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="RJYBQzPjp3j45gk8" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={thinkingText} alt="thinkingText" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="dajHbavKBahG69Sw" style={{ zIndex: 100 }}>
                        <div id="EEAepx7JF4uNke5N" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="gk7FHDaJ546VeWOH" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="l9cif10uynRhSgK8" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.3879683em", textAlign: "center" }}> <span id="lb6TPakX9NFujcf6" style={{ color: "#000000" }}> phase 3 q4 </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="LtpAK3TwwF0WudkV" style={{ zIndex: 104 }}>
                        <div id="TZzw8gkyvsRJG9zl" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="zBk0ptaBZQkGr1tK" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="M9gHZ3fz7G9piajd" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.37108384em", textAlign: "center" }}> <span id="CMZ8rQO3xAZOlpGH" style={{ textDecorationLine: "underline", color: "#000000" }}> 2024 </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="Ja7GtFqQZawEXj4F" style={{ zIndex: 97 }}>
                        <div id="FfrGTMClPf3t7r4y" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="uShc922REsWynp7F" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <ul id="kgRDqfPaH4lFceZs">
                                    <li id="ghfjVmpImMjC41PV" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="WiXVeDps1Wa62JVu" style={{ color: "#2b384c" }}> Listing Flancoin on Cex </span> </li>
                                    <li id="ghfjVmpImMjC41PV" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="WiXVeDps1Wa62JVu" style={{ color: "#2b384c" }}> FlanCraft NFTs Launch </span> </li>
                                    <li id="ghfjVmpImMjC41PV" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="WiXVeDps1Wa62JVu" style={{ color: "#2b384c" }}> Fully integrate $FLAN into FlanCraft </span> </li>
                                </ul>
                                {/* <p id="xAc8TppQE7I8UJi3" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36765108em" }}> <span id="s9YnMl12xmomLxlQ" style={{ color: "#2b384c" }}> marketplace </span> </p>
                                <ul id="YObdrVwDnXwV86mT">
                                    <li id="wq6GjP6jDxtsXMPn" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="rH1eSdOH3oFjoZyx" style={{ color: "#2b384c" }}> development of the game </span> </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="jab6NTOsxJG1AoPC">
                <div id="ymKbfsWDcYEhB9fS" style={{ display: "grid", position: "relative" }}>
                    <div id="Qy8loN6CpRQTcZaH" style={{ zIndex: 42 }}>
                        <div id="noN08efA4ZO2vLME" style={{ paddingTop: "44.94235774%", transform: "rotate(0deg)" }}>
                            <div id="lBtWmGoOBPbRN9Yg" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> <svg id="TodPaX66rdes3xlV" viewBox="0 0 213.3333 95.877" style={{ width: "100%", height: "100%", opacity: "0.3", overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                                    <g id="WzAYK7GxyUYVw2kI" style={{ transform: "scale(1, 1)" }}>
                                        <path id="FfIxVZFoMyANIplB" d="M213.33333333,0 L213.33333333,86.87702984 L106.66666667,95.87702984 L0,86.87702984 L0,0 L213.33333333,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                                    </g>
                                </svg> </div>
                        </div>
                    </div>
                    <div id="tnnvfhsOK8aWlonM" style={{ zIndex: 72 }}>
                        <div id="wLH8ciZstd27qYjz" style={{ paddingTop: "56.22254758%", transform: "rotate(32.85933694deg)" }}>
                            <div id="aZei7HlmRYJ0oL0Y" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="tFFPKv2e996s0uqM" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="jD4TTbzcuDqv3WJ0" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="YIBiPX04OfaizMwx" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                             <img src={thinking} alt="thinking" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="CvFI5lPFjUIakT4K" style={{ display: "grid", position: "relative", gridArea: "4 / 4 / 6 / 6" }}>
                        <div id="AjkgioM2rn2EGcbX" style={{ zIndex: 76 }}>
                            <div id="h5StIPlwVFMWs5ts" style={{ paddingTop: "57.84574468%", transform: "rotate(0deg)" }}>
                                <div id="p4xXAxlitixRocfR" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="IFidOOwlHyeacCUa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="j1qNwzPdM1ZyeExy" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="c94JElkWFpZ0Qk7L" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={thinkingText} alt="thinkingText" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="w71FrSVIipuCIdYL" style={{ zIndex: 101 }}>
                            <div id="xBb3u5DE6n8xFptH" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="UJLP3IZte6UYPHtT" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="WG6TfOmpacNjO0lT" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.3879683em", textAlign: "center" }}> <span id="dmY3Z222uZm8e0Rf" style={{ color: "#000000" }}> phase 4 q1 </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="t9L5M22fpxPQf9e4" style={{ zIndex: 105 }}>
                            <div id="XnPnIZ20SiKh5CaV" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="dxM6oHDgxQfbs8ST" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="sIMcqRU0tXaSeLS5" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.37108384em", textAlign: "center" }}> <span id="nf4R1HJP6scTxiiI" style={{ textDecorationLine: "underline", color: "#000000" }}> 2025 </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="j9NpfbSC069F220j" style={{ zIndex: 106 }}>
                            <div id="IoUdEq22eVxZaXaJ" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="TBloOjomssYNlTVy" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <ul id="YMt0J9Xal28XDMI3">
                                        <li id="OjYun0BUc4hzdIF4" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="j4jQocQI62E2IUff" style={{ color: "#2b384c" }}> Listing Flancoin on important CEX </span> </li>
                                        <li id="n7WPI5g2cXMIuUV8" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "1.7em", lineHeight: "1.36765108em", listStyleType: "disc" }}> <span id="QZp98vf9xR91I30S" style={{ color: "#2b384c" }}> Comming Soon... </span> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    <section id="rGY0P7fHt2bgrSTZ" style={{ position: "relative", overflow: "hidden", display: "grid", alignItems: "center", gridTemplateColumns: "auto 100rem auto", zIndex: 0, marginTop: "-1px" }}>
        <div id="LGRFVx7DtxQX8EVq" style={{ gridArea: "1 / 1 / 2 / 4", display: "grid", position: "absolute", minHeight: "100%", minWidth: "100%" }}>
            <div id="DXKqy0mo0jkJtWAc" style={{ zIndex: 0 }}>
                <div id="mOTAApG5xnrLD2Gd" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="bM3VRcP1Vcw6CJv5" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="wr3uxBsE0u1C3aVJ" style={{ backgroundColor: "#2596ff", opacity: 1.0, transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }} />
                    </div>
                </div>
            </div>
        </div>
        <div id="EGpfzmaYJiovQgkg" style={{ display: "grid", position: "relative", gridArea: "1 / 2 / 2 / 3" }}>
            <div id="HdB3ttuNvt43R2tC" style={{ zIndex: 102 }}>
                <div id="bFtxhx8mf45s2OHj" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                    <div id="slUaocsYVQP1yuBS" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                        <p id="DJX5u10gwuO3XTgQ" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.38283087em", textAlign: "center" }}> <span id="gLk0Mvv186SXGk3D" style={{ color: "#fefefe" }}> how to buy </span> </p>
                    </div>
                </div>
            </div>
            <div id="Oab25eul2oEJGuB7">
                <div id="jYmToHukyP9SMOVY" style={{ display: "grid", position: "relative" }}>
                    <div id="nb9laQ0B8GFPrvwt" style={{ zIndex: 62 }}>
                        <div id="ORpg96gdgp23hoFk" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="D07c7Fu3IsUDMOOe" style={{ opacity: "0.15", display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="KG6w47sqccWqbUYm" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39523891em", textAlign: "center" }}> <span id="VopHZQJNngAvR3M7" style={{ color: "#fefefe" }}> how to buy </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="l5HeG0MrTfB5DSzF" style={{ display: "grid", position: "relative", gridArea: "3 / 5 / 8 / 11" }}>
                        <div id="yAZmu6w2HyjNgJTl" style={{ zIndex: 67 }}>
                            <div id="CEP0bbLjx1AhFZxc" style={{ paddingTop: "44.25%", transform: "rotate(-180deg)" }}>
                                <div id="sZ7WKJ5QnBfyR3k2" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="JRXltiHKrpy4n81F" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="xSTB25A0MigDRtVn" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="xJ4Q4Bqn6mGwtRRC" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={dialog2} alt="Comic Speech Bubble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="YlmbUsEJLP7hCDwo" style={{ zIndex: 71 }}>
                            <div id="c3DHqMQBEYH9krep" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                <div id="cyhuKH9ntlrfD4sJ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="zBxwotbT2o2GWjHW" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="eBIBmmgZXQ8ULVQT" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="xpRoQIqOGSBtOu1K" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={solflare} alt="solflare" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="dmApAxIY8w8Fb6bx" style={{ zIndex: 103 }}>
                            <div id="BuqTsken2g6vumqc" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="pWsdVxXRerxTN5gc" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="TgaFgoh0oi4Wl02B" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.36997422em", textAlign: "center" }}> <span id="laJc4A8JPJ4IIyrF" style={{ color: "#000000" }}> download solflare extension </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="xo1QIF0NKzv7mTQ4" style={{ zIndex: 104 }}>
                            <div id="v1t3jIjAOwYf0Nw3" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="VtDXqg9x1xAn5cdj" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="J4Jxeek3wLrM3lxg" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.380167em", textAlign: "center" }}> <span id="hEAaj2OIBnwjcg8l" style={{ color: "#2b384c" }}> download and install solflare on the Appstore, or, alternatively if you´re on desktop download and install the browser extension </span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="p2cg9ZgC9eFo5QYm" style={{ zIndex: 63 }}>
                        <div id="B5rShxY1Eufl9RQo" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="PJVq1UL0a7HMTgW7" style={{ opacity: "0.15", display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="pTiTjlhnCV9NqkiN" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39523891em", textAlign: "center" }}> <span id="LWnfnA7COZ2UZbnv" style={{ color: "#fefefe" }}> how to buy </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="Hxe3akMNp8LqFSbt" style={{ zIndex: 99 }}>
                        <div id="JjiY6d32LJdNV8eO" style={{ paddingTop: "154.73887814%", transform: "rotate(-78.65782702deg)" }}>
                            <div id="sNuOg5PUheivoEAT" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="JT3PaNKcVGFCfmlj" style={{ width: "100%", height: "100%", opacity: "0.9" }}>
                                    <div id="NsGLkM75buH3DoOY" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="Xfx2pZEtYfXC7ITU" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={arrow} alt="Black Arrow Paint Stroke Scribble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)"
                                            }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="SP7cmUHHgao7vNwF" style={{ display: "grid", position: "relative", gridArea: "10 / 13 / 15 / 21" }}>
                        <div id="yyuvs4gkfTX02xau" style={{ zIndex: 68 }}>
                            <div id="zDAfLRK5ymUEfvyn" style={{ paddingTop: "44.25%", transform: "rotate(-180deg)" }}>
                                <div id="cQyeL0g6Av62naKr" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="oGuOOTkvaJ3NtaKa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="iYHQaLiUw6bZojh4" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="sSXM92TfOAYNcHng" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={dialog2} alt="Comic Speech Bubble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="SSb2kDSSDYk9knGi" style={{ zIndex: 105 }}>
                            <div id="y3rsZPXO53kPvcRk" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="BlW2LUhx7hfxiGJs" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="b7R0NmHHzUsBv43K" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.36997422em", textAlign: "center" }}> <span id="Q59RRvaMmQUZvYWV" style={{ color: "#000000" }}> secure your sol </span> <br /> </p>
                                </div>
                            </div>
                        </div>
                        <div id="CPJeyKW38RbYQio7" style={{ zIndex: 106 }}>
                            <div id="IMkgQawiZmOxdZ5s" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="dQJVzgITo0XWGYZ9" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="RVLFi98TtGWW6zOs" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.380167em", textAlign: "center" }}> <span id="h8NDE69W3sTRFz84" style={{ color: "#2b384c" }}> Now, all you have to do is buy your SOL in the Solflare app. Alternatively, you can use an exchange and deposit using your wallet address. </span> </p>
                                </div>
                            </div>
                        </div>
                        <div id="tkveMFqifVgQCGjY" style={{ zIndex: 72 }}>
                            <div id="QIHBhzsdh88BwzJ5" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                <div id="muSgqvgaS0oFeYXE" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="FGK5ZS9pAdX64FpD" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="OUA7PPVoGacegdat" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="zuetmnv0otsom60X" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={sol} alt="sol" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="buq0dcJjOklcfd5K" style={{ zIndex: 64 }}>
                        <div id="kAmGRdLSUEhXiDbp" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="rbXmY33vqCWArhIA" style={{ opacity: "0.15", display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="JWiDb8FIzHDg7gnJ" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39523891em", textAlign: "center" }}> <span id="QN0OucfA6UujBXmP" style={{ color: "#fefefe" }}> how to buy </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="QqGTaDFxrzargIqZ" style={{ zIndex: 100 }}>
                        <div id="mJAbx3v171RrmQFM" style={{ paddingTop: "154.73887814%", transform: "rotate(-107.14140213deg)" }}>
                            <div id="T8dEYivu2O6a3VBA" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="xpOHs03PotPbMtsB" style={{ width: "100%", height: "100%", opacity: "0.9" }}>
                                    <div id="IS3YqKwoOz4d62eo" style={{ transform: "scale(1, -1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="r040AqCgHTPuAgk7" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={arrow} alt="Black Arrow Paint Stroke Scribble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)"
                                            }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="UDMvbS2QoTObTVCF" style={{ display: "grid", position: "relative", gridArea: "14 / 6 / 20 / 12" }}>
                        <div id="WLya5JLwizKxteoU" style={{ zIndex: 69 }}>
                            <div id="GEB6KJcSRtgk2mH9" style={{ paddingTop: "44.25%", transform: "rotate(0deg)" }}>
                                <div id="MPNT22ZhEFZZDAx2" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}></div>
                            </div>
                        </div>
                        <div id="yyuvs4gkfTX02xau" style={{ zIndex: 68 }}>
                            <div id="zDAfLRK5ymUEfvyn" style={{ paddingTop: "44.25%", transform: "rotate(-180deg)" }}>
                                <div id="cQyeL0g6Av62naKr" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="oGuOOTkvaJ3NtaKa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="iYHQaLiUw6bZojh4" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="sSXM92TfOAYNcHng" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={dialog2} alt="Comic Speech Bubble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="nKj5kdGuRetg5uw9" style={{ display: "grid", position: "relative", gridArea: "3 / 3 / 8 / 4" }}>
                            <div id="j0ub4eSontYYl3vt" style={{ display: "grid", position: "relative", gridArea: "2 / 3 / 5 / 5" }}>
                                <div id="HG2qC2TSDK1jxcw7" style={{ zIndex: 75 }}>
                                    <div id="LvCpVyooGBHRNaGF" style={{ paddingTop: "98.625%", transform: "rotate(-21.6419108deg)" }}>
                                        <div id="ocpxg7cXz9ra3EEO" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="nKVAY5m15v4I4WoN" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="k3DsSEqLfyaJYAtD" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="ZZFV6hp8GBk556zC" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                        <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="pw9gzq8vTLjjprOo" style={{ zIndex: 78 }}>
                                <div id="H1mCu3n5gH0hocGh" style={{ paddingTop: "85.30020704%", transform: "rotate(-21.6419108deg)" }}>
                                    <div id="qP5EPLuAz4gXX5Mn" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="QwNl1YbcYTqYIGNH" style={{ width: "100%", height: "100%", opacity: "0.8" }}>
                                            <div id="w2s2ShbqcfscTDwM" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="sNkpPoojt8ceuYMe" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                                    <img src={logoCoin} alt="logoCoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="kWkOs6CkzGsbAvFb" style={{ zIndex: 107 }}>
                            <div id="ul9dWi2JjsCVh6VZ" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="MBycEvEIHnBbMN1o" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="A9aGIDOijYr2wL93" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.36997422em", textAlign: "center" }}> <span id="yvLjVrnOHtwRXTus" style={{ color: "#000000" }}> buy some $flan </span> <br /> </p>
                                </div>
                            </div>
                        </div>
                        <div id="PuqKRscYm94ANdSF" style={{ zIndex: 73 }}>
                            <div id="Ap1KKqmtwzFTIU1O" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                <div id="GpzB9X8no7iUK42e" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                    <p id="zwt1MZpi3HRpuXuI" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.380167em", textAlign: "center" }}> <span id="ryaFMhJQt2JJFUAx" style={{ color: "#2b384c" }}> now go to raydium and paste the flancoin contract address to swap your sol. </span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ManO5KunWHeXsu8B" style={{ zIndex: 65 }}>
                        <div id="wntTXg1pNDLr1y48" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="VbzJo29u9B8nSb2m" style={{ opacity: "0.15", display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="AhkYxgh0aAbkY3sS" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39523891em", textAlign: "center" }}> <span id="w1pdYQtergM7HW1G" style={{ color: "#fefefe" }}> how to buy </span> </p>
                            </div>
                        </div>
                    </div>
                    <div id="w2URqip37N17zghD" style={{ zIndex: 101 }}>
                        <div id="hMO1PvLpc6SKTCg8" style={{ paddingTop: "154.73887814%", transform: "rotate(-78.65782702deg)" }}>
                            <div id="aBSib2OCEmUavjHU" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="Gg5YOu16X9WennRy" style={{ width: "100%", height: "100%", opacity: "0.9" }}>
                                    <div id="UoY51d6G6RpPJWP4" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="j6gIsOAnf0bjdmbO" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={arrow} alt="Black Arrow Paint Stroke Scribble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)"
                                            }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="UyGWI7iNar2uBI1Q" style={{ zIndex: 66 }}>
                        <div id="FFGse8IMeuZaaSDo" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="ApDXjoa4ggwN7X3t" style={{ opacity: "0.15", display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="yNeSTF8IWPgOcyrf" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.39523891em", textAlign: "center" }}> <span id="TkmNXh15Jav6kZ0e" style={{ color: "#fefefe" }}> how to buy </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="K26rGE7fGIEODKyr">
                <div id="zyoD1VCXaEkTRdCV" style={{ display: "grid", position: "relative" }}>
                    <div id="A07GYNHq8ZNJoLk3" style={{ zIndex: 70 }}>
                        <div id="hH4g3UOF8VZ8AGXA" style={{ paddingTop: "44.25%", transform: "rotate(-180deg)" }}>
                            <div id="BhTqYZdxz5oE6T2U" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="xnjKYnKymk0LBTUW" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="rGwmCtLoOfIrtnib" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="Q7D5FmGIuNKzzdYd" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={dialog2} alt="Comic Speech Bubble" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="UeE2C43Y77MDSSrA" style={{ zIndex: 79 }}>
                        <div id="Bh8hiaT6hyXa0VNr" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="iKB5JLcXCkrWqFkh" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="kbITgkauHSnD1NLA" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.36997422em", textAlign: "center" }}> <span id="LbEIVJQ357UoHQyn" style={{ color: "#000000" }}> add to your wallet </span> <br /> </p>
                            </div>
                        </div>
                    </div>
                    <div id="HLOvpBrDvRGYIuwl" style={{ zIndex: 80 }}>
                        <div id="RHoDRXgmtgUoqtMa" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="mM1ubGkyzwdZr5w5" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="fix3cDfsvdS6camo" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.380167em", textAlign: "center" }}> <span id="zjNJQhcO8QuYtoW4" style={{ color: "#2b384c" }}> Now you're all set! Welcome aboard the next gem. Simply import the contract address to view your holdings and track your earnings. </span> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="mMd6EXMSLAeddo8F">
                <div id="VIuGQUl6ty6C6s7d" style={{ display: "grid", position: "relative" }}>
                    <div id="r9LMZwFMU2WA3o7e" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 11 / 10" }}>
                        <div id="R64zRMRicQ1w366D" style={{ zIndex: 82 }}>
                            <div id="aP2vJXLwUD8Pbwbl" style={{ paddingTop: "91.125%", transform: "rotate(0deg)" }}>
                                <div id="BPAUICKBnLAfL0qA" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="zXxXz4tknDWc2hlA" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="HlSteaAdRmMAAWvx" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="LVrUKoWIGKnYkQmM" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                 <img src={wallet} alt="3D Wallet with dollar banknote and coin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform:
                                                "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div id="YdoQx15ewEjK4I1h" style={{ display: "grid", position: "relative", gridArea: "3 / 6 / 6 / 10" }}>
                            <div id="u3aqQhjrWqO7Mw6k" style={{ zIndex: 95 }}>
                                <div id="AO4nxFfZ4c45vNLK" style={{ paddingTop: "98.625%", transform: "rotate(-15.52833059deg)" }}>
                                    <div id="ysTfeHbv6UB86pnQ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="nzz8xTtXXhpFGVSR" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                            <div id="EPUe9sOjiBtqotVM" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="FhafgQlZWiHXWkn3" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="fDd5nPi0a44vVZgA" style={{ zIndex: 97 }}>
                                <div id="yHYyoiJ0L9dPFjH7" style={{ paddingTop: "244.49966793%", transform: "rotate(-15.52833059deg)" }}>
                                    <div id="AesR1qS1zT4w23tW" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="Hcs0jny5Gu25rZCj" style={{ width: "100%", height: "100%", opacity: "0.54" }}>
                                            <div id="nQXzna53hnDrr1KM" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="vBbaCmL2F0Svc59y" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                    "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="ib4cBlvu836v64RA" style={{ zIndex: 96 }}>
                                <div id="Jld1ukxE2YdHgFcF" style={{ paddingTop: "244.49966793%", transform: "rotate(-15.52833059deg)" }}>
                                    <div id="N7cnnjPgIGjRzbtL" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="Ebo3qkSlnkz7hoR8" style={{ width: "100%", height: "100%", opacity: "0.93" }}>
                                            <div id="e5TINMBjqyUMgsed" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="U8W2WIXuUal3ow4g" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                    "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                         <div id="SXmsKrcli8Z5BzbD" style={{ zIndex: 98 }}>
                            <div id="EofGexHQhN2sMPDf" style={{ paddingTop: "85.30020704%", transform: "rotate(-15.52833059deg)" }}>
                                <div id="uQ83vURnkwKMq93F" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="aGH5C5r1rDNxtsmp" style={{ width: "100%", height: "100%", opacity: "0.8" }}>
                                        <div id="X9mxilq07jRiEvdq" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="AJEbr1a0ADDZoAGE" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={logoCoin} alt="logoCoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                         <div id="oOavlG9URgCVOBGV" style={{ display: "grid", position: "relative", gridArea: "7 / 3 / 10 / 8" }}>
                            <div id="C08CZI3a5HhOdRzk" style={{ zIndex: 87 }}>
                                <div id="KJA3HlQulx43L5Dd" style={{ paddingTop: "98.625%", transform: "rotate(-87.3654307deg)" }}>
                                    <div id="G6VtO9YDKcGBllmu" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="IX8lHETL3futHp08" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                            <div id="fCu9UbpKHq2dKFyD" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="VY02mVBLFGXMmgNs" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                    <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mJS1KmvYRsXd0Tmy" style={{ zIndex: 89 }}>
                                <div id="PyQx84RZUZU04XlJ" style={{ paddingTop: "244.49966793%", transform: "rotate(-87.3654307deg)" }}>
                                    <div id="BBP4aW7TWGF9xyZe" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="mJHsyPUK2NBtId3f" style={{ width: "100%", height: "100%", opacity: "0.54" }}>
                                            <div id="hvpis21DitJyIA5w" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="lAoCyzarLIopWIOn" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                    <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                    "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="OHzFKHpXvMaX0183" style={{ zIndex: 88 }}>
                                <div id="iAOQfjHRmkw7c3fh" style={{ paddingTop: "244.49966793%", transform: "rotate(-87.3654307deg)" }}>
                                    <div id="m6PNEHDUF1UcfyjH" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <div id="hLHW1VWPRelrAGT4" style={{ width: "100%", height: "100%", opacity: "0.93" }}>
                                            <div id="et1lNrWPyQV1mHuC" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                <div id="jnVxLRq9lJ8Mz9Gf" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}> 
                                                    <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                    "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                         <div id="XIyDdTI6CuvRpGEV" style={{ zIndex: 90 }}>
                            <div id="DpF7P95tkHzRuw0X" style={{ paddingTop: "85.30020704%", transform: "rotate(-87.3654307deg)" }}>
                                <div id="fVqZ6edYj2wr6sll" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="csSBjkJIfdcbdRir" style={{ width: "100%", height: "100%", opacity: "0.8" }}>
                                        <div id="PeFievqMVoOpY3ew" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="A5H5fkg8lgQx8Znc" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                                <img src={logoCoin} alt="logoCoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                     <div id="KgJnv1MihVmVSmEo" style={{ zIndex: 86 }}>
                        <div id="Kz9022zAFkm5FSIX" style={{ paddingTop: "85.30020704%", transform: "rotate(48.25818349deg)" }}>
                            <div id="n619sstm46x90CTn" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="Ij0GbsGwcHOuh9bU" style={{ width: "100%", height: "100%", opacity: "0.8" }}>
                                    <div id="qUXLcfQHk1AjB4hx" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="UX8TFQkOPDg4EPq3" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                            <img src={logoCoin}  alt="logoCoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                     <div id="q7nCXrDt2zx0S5u1" style={{ display: "grid", position: "relative", gridArea: "3 / 4 / 6 / 5" }}>
                        <div id="bvJW75wGr7jNfnxI" style={{ zIndex: 83 }}>
                            <div id="G9BVhu0doExHOVUP" style={{ paddingTop: "98.625%", transform: "rotate(48.25818349deg)" }}>
                                <div id="jNhrQMS2iu7nqob6" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="ZJAYjinbNCwXWbst" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="jZTEMzpsTPX79CpR" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="NmeY7DUFLeVBpu5y" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="rZO62tyysC4xIrRp" style={{ zIndex: 84 }}>
                            <div id="jzYHAfPW89AZEqyA" style={{ paddingTop: "244.49966793%", transform: "rotate(48.25818349deg)" }}>
                                <div id="xkOgwBMQtnj9Dtct" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="UzKfYBUAZcU8TvHJ" style={{ width: "100%", height: "100%", opacity: "0.93" }}>
                                        <div id="ZHHl4Cu5JbDcInMQ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  <div id="hjTcVwuLcEAhQYit" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}>  
                                            <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} />  </div>  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="hFZr9gcpBqxj6Kkw" style={{ zIndex: 85 }}>
                            <div id="u9VuBqMC1zRXlvnC" style={{ paddingTop: "244.49966793%", transform: "rotate(48.25818349deg)" }}>
                                <div id="cZ6x9h1Trqmcv1H9" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                    <div id="wPFpEKDsnq80M8v9" style={{ width: "100%", height: "100%", opacity: "0.54" }}>  <div id="rMFuLjR8OKY39ibL" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                        <div id="vLvSTpegre7LtPJo" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}>  
                                            <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} />  
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div> 
                     <div id="v9iW7ys0R872mbIV" style={{ display: "grid", position: "relative", gridArea: "7 / 8 / 10 / 11" }}>
                        <div id="nJow2uWGyMNowbnE" style={{ zIndex: 91 }}>
                            <div id="s4CeyVJ44zlkMbBk" style={{ paddingTop: "98.625%", transform: "rotate(5.73822166deg)" }}>
                                <div id="zxOPMRVF9l7PsvRs" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="TonxKaSmA6SaoRlh" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                        <div id="UhqNoawFEQDHwkUL" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="lLabHbdGyXW2kGFR" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div id="FjN9tMktcmICGFCD" style={{ zIndex: 92 }}>
                            <div id="FyfvKdg9KNkLfdPJ" style={{ paddingTop: "244.49966793%", transform: "rotate(5.73822166deg)" }}>  
                                <div id="BLHwHiUeQ3foZZzU" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="eNyPTGfekPibWvSq" style={{ width: "100%", height: "100%", opacity: "0.93" }}>  
                                        <div id="q0iyyOqnGEksFMYn" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="Jw1NQynVNfa9d7u0" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}>  
                                                <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>
                        </div>
                        <div id="gzXvgnkT7fAuMqlv" style={{ zIndex: 93 }}>  <div id="F2DC8IH5YRQg7orf" style={{ paddingTop: "244.49966793%", transform: "rotate(5.73822166deg)" }}>  
                            <div id="CXrh00rBdp9sR7sp" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> 
                                <div id="snB8vAXMZ0YTX779" style={{ width: "100%", height: "100%", opacity: "0.54" }}>  
                                    <div id="QvbCd0nAVNgf4nJz" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                        <div id="uyd4R6UlpSo4TJik" style={{ width: "calc(1299.7906998% * max(1, var(--scale-fill, 1)))", height: "calc(524.30278884% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0}}>  
                                            <img src={coin} alt="Coin 3D Icon" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "24.61899962% 50.56990881%", transform:
                                                "translate(-24.61899962%, -50.56990881%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>
                    </div> 
                     <div id="qN6pschewRRkPdgd" style={{ zIndex: 94 }}>  
                        <div id="rhrNR50reHzXwSP5" style={{ paddingTop: "85.30020704%", transform: "rotate(5.73822166deg)" }}>  
                            <div id="elp4Jk8bhvDCyHOy" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> 
                                <div id="t7EDBwWGAsAZMBDd" style={{ width: "100%", height: "100%", opacity: "0.8" }}>  
                                    <div id="GvwG5vvH7sl6MbLS" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}> 
                                        <div id="fSpXne5qas4MQgOv" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                            <img src={logoCoin} alt="logoCoin" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                    </div> 
                </div>
            </div>
            <div id="F5MzBcQM9D34KSUc">
                <div id="AYKrXdJKUX76DB3i" style={{ display: "grid", position: "relative" }}>  
                    <div id="SdvUm9cQ9gJPfwg0" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>  
                        <div id="ZXvRtwPBITdZuZQh" style={{ zIndex: 25 }}>  
                            <div id="Nc3W5FOHaGwxQ7pR" style={{ paddingTop: "97.9381463%", transform: "rotate(-180deg)" }}>  
                                <div id="AuN22SoKxjpM53Am" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <svg id="yCoFfCrIKdeBktpG" viewBox="0 0 142.5198 139.5812" style={{ width: "100%", height: "100%", opacity: "0.36", overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>  <g id="jOhVayGfcWsiPvzw" style={{ transform: "scale(1, 1)" }}> 
                                            <path id="e6Ja7shBDoSRcGVw" d="M47.53226457,138.07973734 C54.83887353,138.98633829 63.14558408,139.58123652 71.29826354,139.58123652 C79.45122008,139.58123652 87.29638629,139.0712382 94.52597188,138.16463724 C94.68000818,138.13633601 94.83376219,138.13633601 94.98751621,138.10803477 C122.13781288,134.48163477 142.1352546,124.90573756 142.519786,112.03606797 L142.519786,0 L0,0 L0,111.95291948 C0.38456497,124.96233622 20.0742952,134.53833643 47.53226457,138.07973734 Z" style={{ fill: "#207ce4", opacity: 1.0 }} /> 
                                        </g>  
                                    </svg>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="pbpEdbCjv7ZNg2PT" style={{ display: "grid", position: "relative", gridArea: "3 / 3 / 7 / 24" }}>  
                            <div id="rSckvVL3rpkrTudl" style={{ zIndex: 58 }}>  
                                <div id="Yn0rxZXGRD5GtkUO" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>  
                                    <div id="kNT5sZmhuWtKRpz3" style={{ transformOrigin: "0px 0px", width: "100%", height: "auto", minHeight: 1, pointerEvents: "none", position: "absolute", transform: "scale(1.3) translate(-11.53846154%, -27.62758649%)" }}>  
                                        <img src={cookYourOwnFlan} alt="Cook your own Flan" loading="lazy" 
                                            style={{ width: "100%", height: "100%", display: "block", objectFit: "cover" }} />  
                                    </div>
                                </div>
                            </div>
                        <div id="S1c0Zgj9BZxEOiMs" style={{ zIndex: 26 }}>  
                            <div id="lvzjmEbGq0NlR0Ww" style={{ paddingTop: "70.375%", transform: "rotate(-14.04770748deg)" }}>  
                                <div id="SOLE6Y1HSq1IzUU0" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="vpmK8DrTIlGlNchW" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="a8Otgfk9Zx2EDIyJ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="WzYnsEDSd4R6VKYa" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            </div>  
                            <div id="sLQVxuoNs3VLm6la" style={{ zIndex: 27 }}>  
                                <div id="uEtEMmi1tkvs7Abs" style={{ paddingTop: "80.125%", transform: "rotate(0deg)" }}>  
                                    <div id="IljVati96X9PgYc1" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="ftZbIlSmr2zNV3IB" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="ArOIMembIzz0EDGo" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="AbtC5LpSyKamHiV7" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="wlj1LwHL74bm6vxf" style={{ zIndex: 59 }}>  
                                <div id="aVcGgpEFIhc8A2qr" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>  
                                    <div id="rYke2X8rKFLZ9j2T" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}> 
                                        <p id="kdWu8br9EuuI6yyi" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", marginLeft: "0em", lineHeight: "1.38149897em", textAlign: "center", textTransform: "none", letterSpacing: "0em" }}>  
                                            <a href="/cook"><span id="eejp7uonNp1TANop" style={{ color: "#fefefe" }}> <span style={{textShadow: "1rem 1rem black", fontSize: "5rem"}}>START<br/></span>DO YOU LOVE FLAN? SHARE THE DELICIOUSNESS! NOW YOU CAN WHIP UP YOUR OWN RANDOM AVATAR. </span></a>  <br />  
                                        </p>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="EnXMoLfQqkHhnjKI" style={{ zIndex: 40 }}>
                            <div id="cMeqnLdB2ywwrgUL" style={{ paddingTop: "80.125%", transform: "rotate(18.54882714deg)" }}>  
                                <div id="qo6koogsnBMy7lvQ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="EqO3ZBYwqB4nJ3vw" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="f3lvjh0Hqd2oaqLi" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="gfoTkIYizISCowiA" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="Hu05YetmgrvmHIYM" style={{ zIndex: 28 }}>  
                            <div id="hyfLGCiNTqkdDxtA" style={{ paddingTop: "70.375%", transform: "rotate(31.38864071deg)" }}>  
                                <div id="KjODGWXwBBjJNe4x" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="XniyN0gnu9gCtTUD" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="fz0W4rSCQkzc5Fqt" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="eoyg7lX9eREB0GMO" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="US3WFcJLr2mZ0kw4" style={{ display: "grid", position: "relative", gridArea: "8 / 10 / 24 / 15" }}>  
                            <div id="C9g1LnLldRwBMtJt" style={{ zIndex: 57 }}>  
                                <div id="kmW96cimkwS2SoOd" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>  
                                    <div id="yRCYGeKOWi9jCKuA" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="V3AFXXPneUbO1FSM" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                            <div id="Ame3Wvj6Jz8cC9Z8" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="Vhe95MHCuCdRjWUB" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={flanChef} alt="flanChef" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="pCnmyaZmTjFneo3e" style={{ display: "grid", position: "relative", gridArea: "3 / 4 / 5 / 12" }}>  
                                <div id="ricjYoNZJd1hArtx" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>  
                                    <div id="MyIeVZdedeuwtSFx" style={{ zIndex: 44 }}>  
                                        <div id="ddIVDALHgO0Kflhd" style={{ paddingTop: "27.75%", transform: "rotate(0deg)" }}>  
                                            <div id="fOE1fe5miGPWHQCw" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                                <div id="FAtsikOBe3GcugRp" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                    <div id="ptjJDc4yoj1y3Gwn" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                        <div id="mEg7ucGhCtyEXZ3P" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1}}> 
                                                            <img src={dialog1} alt="Speech Bubble Illustration" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                        </div>  
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                    <div id="jhLQyqMnl5LNdUke" style={{ zIndex: 45 }}>  
                                        <div id="ethO1lQgC1SKzF8f" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>  
                                            <div id="lylpfQnUPOkMjfHp" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>  
                                                <p id="i57Jb9ZNmGP2hrZJ" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.37623804em", textAlign: "center" }}>  
                                                    <span id="CtNT55Qmv09zthRH" style={{ color: "#2b384c" }}> cook a flan </span>
                                                    
                                                </p>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="KTZ51D392zjJ8nwO" style={{ zIndex: 33 }}>  
                                <div id="xPMMH9Cttwn0AvWN" style={{ paddingTop: "70.375%", transform: "rotate(-15.51081594deg)" }}>  
                                    <div id="FWPzeNmwocHSoXMt" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="eg1lbiO7StKjh7oR" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="qDYVq7NYpQRAt4Zy" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="yaFvUYS7wfeHb8GK" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>
                                            </div>
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="k5RGsBdKROePyjIU" style={{ zIndex: 37 }}>  
                                <div id="z57s2E3MfQrkPwfb" style={{ paddingTop: "80.125%", transform: "rotate(18.75944763deg)" }}>  
                                    <div id="ZfPs8Q42dshm4KGu" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="T1MSfFv0nc4cZY3z" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="VBjhkLHVAChmewLQ" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="glY0Loc4kIRN9N9l" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="ngdy7hvOqapTNFu9" style={{ zIndex: 29 }}>  
                                <div id="GSHhtLobfQ3JPVww" style={{ paddingTop: "70.375%", transform: "rotate(15.98822639deg)" }}>  
                                    <div id="g9R2zHj7r3eMzmcS" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="aqP95YW6grJYFEgQ" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="GbpsqPfOfeWxa7Mh" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="UGm1CGgxGBC5yJuB" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform:"translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                            <div id="zmXBH92cMySiI4Lx" style={{ zIndex: 30 }}>  
                                <div id="VqfaYsXqCHrGO9FD" style={{ paddingTop: "70.375%", transform: "rotate(-17.77268866deg)" }}>  
                                    <div id="Q5XPTQbq9HXONX6p" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="nl1BdW2MXeJPYvJY" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="IpYIY0VTDQc09H4K" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="vdW8JJpxCGsNkBxP" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform:"translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div> 
                            <div id="gtGL5jSJsi68KN8X" style={{ zIndex: 38 }}>  
                                <div id="WcamxWSsQXq4OgfG" style={{ paddingTop: "80.125%", transform: "rotate(26.18985888deg)" }}>  
                                    <div id="dyLkeNRVlvUeW1os" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                        <div id="ehdJp72n7bsj1P7H" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                            <div id="gv5ZP4WPjL4QioRF" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                <div id="Vfk0KWQOuozKZKG1" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                    <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="QPxKsM1eLjZUORVI" style={{ zIndex: 36 }}>  
                            <div id="kjVPYSEdUDZbC04k" style={{ paddingTop: "80.125%", transform: "rotate(-24.03064652deg)" }}>  
                                <div id="DPnbBSEulp1CaPTT" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="vG0HIxZRhpfuuHre" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="f2xyCVlmabwxF0S1" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="vXy6wPhfJPGQE09l" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="MoFr0cdsqMQbsRQe" style={{ zIndex: 32 }}>  
                            <div id="AAYyrAgj4kpGhkN8" style={{ paddingTop: "70.375%", transform: "rotate(-2.11878382deg)" }}> 
                                <div id="J47rhwwXo0J17Sdp" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="uprCZhkBjitkfuAR" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="ByE9vyH7QcX6UBgu" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="lbL7dOoZAF9IIm0Z" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="VNpCLoqpk66TD0xg" style={{ zIndex: 34 }}>  
                            <div id="hiAvKfj9oIfvVsCS" style={{ paddingTop: "70.375%", transform: "rotate(-9.80289043deg)" }}>  
                                <div id="yxN44rQnRL0CujUk" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="pvolD3dYGXBFyDJ3" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="IZdazbEe3qN8piHW" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="yve8Phq7p3sIRUf7" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="HVs5M0VOEFsjJYdy" style={{ zIndex: 39 }}>  
                            <div id="KOXLTqEAO1HSzkyW" style={{ paddingTop: "80.125%", transform: "rotate(50.32246245deg)" }}>  
                                <div id="YjQBESNPvhY7mIjT" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="LQOu7A3QnblABPrZ" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="dqb4JjnXn8MGFTmz" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="c2Zu1ORSbB8bi7gv" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="S8CO7SnwnwwXLHZF" style={{ zIndex: 41 }}>  
                            <div id="QuNfdZevHJDZ7I7a" style={{ paddingTop: "80.125%", transform: "rotate(-15.57112583deg)" }}>  
                                <div id="VzhzXVusDwj03f4J" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="gLKbmNLXxiGZEmKT" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="QJLPCV88YFtwgo7A" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="nT2Mt45z3XjEO34L" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={foodPan} alt="Food Cooked in Pan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="r3KPJz483stczGpb" style={{ zIndex: 31 }}>  
                            <div id="e01eeuIeiwC0PdPi" style={{ paddingTop: "70.375%", transform: "rotate(16.19684079deg)" }}>  
                                <div id="jZAjMUcB7ccTZ97N" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="t3DGwWpST01imWAp" style={{ width: "100%", height: "100%", opacity: "0.6" }}>   
                                        <div id="cfffWrZ8SN51sbFt" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="cXRDwLCVievgIgc6" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="HaN8YyYWHxNQYEWh" style={{ zIndex: 35 }}>  
                            <div id="OVN4IoRQHMlV5wQW" style={{ paddingTop: "70.375%", transform: "rotate(-17.98809692deg)" }}>  
                                <div id="HbNIgXh7bkE0RrnB" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <div id="VpR6UcyukbnMuhZG" style={{ width: "100%", height: "100%", opacity: "0.6" }}>  
                                        <div id="FUvhgyjXcnas4MoB" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                            <div id="Ulqk1Ra3VzUNqSN2" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div> 
            </div>  
            <div id="B9p0ODVWgaqTmPlu">  
                <div id="L9PlS3UYTaS7Ohr2" style={{ display: "grid", position: "relative" }}>  
                    <div id="nRxHPzfidMTfcu8R" style={{ zIndex: 42 }}>  
                        <div id="aZtyPZCmkzgrDSP2" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>  
                            <svg id="wj9CfvkFuG3JQGsG" viewBox="0 0 255.1448 96.1417" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>  
                                <g id="ZRKGAP4qJ8qdSHhm" style={{ transform: "scale(1, 1)" }}>  
                                    <g id="zi6tXjvfT58Vnfma" style={{ clipPath: "url(#QPNZfOukosgi9sM0)" }}>  
                                        <clipPath id="QPNZfOukosgi9sM0"> 
                                            <path d="M85.09422111,1.50153995 C98.13261576,0.59494799 113.04582987,0 127.64109302,0 C142.23685218,0 156.28159241,0.509956 169.22429481,1.41655004 C169.50005695,1.44489002 169.77531373,1.44489002 170.05057051,1.47320998 C218.65615177,5.09959984 254.45639699,14.67549992 255.14480097,26.58025797 L255.14480097,96.14165924 L0,96.14165924 L0,26.63188006 C0.68846408,14.6189003 35.93783149,5.04293013 85.09422111,1.50153995 Z" /> 
                                        </clipPath> 
                                        <path id="od6KxonzseWoWXzN" d="M85.09422111,1.50153995 C98.13261576,0.59494799 113.04582987,0 127.64109302,0 C142.23685218,0 156.28159241,0.509956 169.22429481,1.41655004 C169.50005695,1.44489002 169.77531373,1.44489002 170.05057051,1.47320998 C218.65615177,5.09959984 254.45639699,14.67549992 255.14480097,26.58025797 L255.14480097,96.14165924 L0,96.14165924 L0,26.63188006 C0.68846408,14.6189003 35.93783149,5.04293013 85.09422111,1.50153995 Z" style={{ fill: "#207ce4", opacity: 1.0 }} /> 
                                        <path id="CkCJzjAdQENqRNyq" d="M85.09422111,1.50153995 C98.13261576,0.59494799 113.04582987,0 127.64109302,0 C142.23685218,0 156.28159241,0.509956 169.22429481,1.41655004 C169.50005695,1.44489002 169.77531373,1.44489002 170.05057051,1.47320998 C218.65615177,5.09959984 254.45639699,14.67549992 255.14480097,26.58025797 L255.14480097,96.14165924 L0,96.14165924 L0,26.63188006 C0.68846408,14.6189003 35.93783149,5.04293013 85.09422111,1.50153995 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#000000" }} /> 
                                    </g> 
                                </g>  
                            </svg>  
                        </div>  
                    </div>  
                    <div id="cHLQWzOqyFI5ssTY" style={{ zIndex: 56 }}>  
                        <div id="Vce95ir3Xa8FDRGW" style={{ paddingTop: "100%", transform: "rotate(-12.06518728deg)" }}>  
                            <div id="pUUEOtj1MiG09BXP" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                <div id="hDCPW2kljlW8FZBr" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                    <div id="kVpygifh2D8oj2xa" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                        <div id="FnfoPQb1sVK735ZR" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                            <img src={flanLogo} alt="flanLogo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                    <div id="ffXSVxPQ9xV6IFjP" style={{ display: "grid", position: "relative", gridArea: "3 / 3 / 6 / 6" }}>  
                        <div id="sd5YMGnoq3fbsWHh" style={{ zIndex: 46 }}>  
                            <div id="fCSUBada2WY4IztM" style={{ paddingTop: "38.47865057%", transform: "rotate(0deg)" }}>  
                                <div id="MDrvI87eNADlDoSZ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                    <svg id="gCqZNeTlE9emUwJQ" viewBox="0 0 166.326 64.0" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>  
                                        <g id="howL7nabFpSFZDhg" style={{ transform: "scale(1, 1)" }}>  
                                            <g id="U58S4vOplwWC4Epz" style={{ clipPath: "url(#lOegLypkSa7q3hX6)" }}>  
                                                <clipPath id="lOegLypkSa7q3hX6"> 
                                                    <path d="M17.16343397,0 L149.16257006,0 C158.6416729,0 166.32600403,7.68433113 166.32600403,17.16343397 L166.32600403,46.83656603 C166.32600403,56.31566887 158.64167289,64 149.16257006,64 L17.16343397,64 C7.68433113,64 0,56.31566886 0,46.83656603 L0,17.16343397 C0,7.68433113 7.68433114,0 17.16343397,0 Z" /> 
                                                </clipPath> 
                                                <path id="r0A5E2GHaEz3Gqtx" d="M17.16343397,0 L149.16257006,0 C158.6416729,0 166.32600403,7.68433113 166.32600403,17.16343397 L166.32600403,46.83656603 C166.32600403,56.31566887 158.64167289,64 149.16257006,64 L17.16343397,64 C7.68433113,64 0,56.31566886 0,46.83656603 L0,17.16343397 C0,7.68433113 7.68433114,0 17.16343397,0 Z" style={{ fill: "#ffffff", opacity: 1.0 }} /> 
                                                <path id="c8OZUuTKoQgyhGpB" d="M17.16343397,0 L149.16257006,0 C158.6416729,0 166.32600403,7.68433113 166.32600403,17.16343397 L166.32600403,46.83656603 C166.32600403,56.31566887 158.64167289,64 149.16257006,64 L17.16343397,64 C7.68433113,64 0,56.31566886 0,46.83656603 L0,17.16343397 C0,7.68433113 7.68433114,0 17.16343397,0 Z" style={{ fill: "none", strokeLinecap: "butt", vectorEffect: "non-scaling-stroke", stroke: "#000000" }} /> 
                                            </g>  
                                        </g>  
                                    </svg>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="PF3I000s4TdzIAYR" style={{ zIndex: 60 }}>  
                            <div id="xC5zov6IraY6WkCY" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>  
                                <div id="obI43gSwGwUDQTnU" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>  
                                    <p id="rrJDjY8tlqDqhVtL" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.37723643em" }}>  
                                        <span id="oVVAfK21t0WbTNVM" style={{ color: "#2b384c" }}> JOIN OUR COMMUNITY! </span>  
                                        <br />  
                                    </p>  
                                    <p id="frRWqp8YDjb5wqom" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36997632em" }}>  
                                        <span id="K5sNOVRCyIXk52MC" style={{ color: "#2b384c" }}> JOIN OUR TELEGRAM AND FOLLOW US ON X FOR THE LATEST UPDATES AND A WARM WELCOME FROM OUR FRIENDLY COMMUNITY. LET'S HEAD TOWARDS MILLIONS TOGETHER, ENJOYING MEMES AND ARTWORK ALONG THE WAY! </span>  
                                        <br />  
                                    </p>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="UkoCrrYaUfiD1SFf" style={{ display: "grid", position: "relative", gridArea: "5 / 4 / 9 / 5" }}>  
                            <div id="RDQHhf2nCjTQmHee" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>  
                                <div id="sa0ZaVZQvaFMsSzv" style={{ zIndex: 51 }}>  
                                    <div id="aLWok1cgLlBvw8LE" style={{ paddingTop: "71.45454545%", transform: "rotate(0deg)" }}>  
                                        <div id="AKTtCfCUWTXg0OWo" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="YH1ryIbWsipupglg" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="Zp2AVr1VH6irtfRY" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="LnuIH9jCUama9BZM" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <img src={darkRRSSCloud} alt="darkRRSSCloud" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                                <div id="FEQ0xIcj3Ocvip2i" style={{ zIndex: 52 }}>  
                                    <div id="HVseAzDtr841SVyo" style={{ paddingTop: "84.91210938%", transform: "rotate(0deg)" }}> 
                                        <div id="eiwquot8w9kusl8G" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="mtCl8wNqzIxO1C2h" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="V0njrKG1uvTKppoE" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="DWnG0bXC9JpJzint" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <a href={linkTelegram}  target="_blank" rel="noreferrer"><img src={telegramIcon} alt="telegram" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  </a>
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="XTPRKnPjixpetYpf" style={{ display: "grid", position: "relative", gridArea: "6 / 6 / 8 / 7" }}>  
                            <div id="D1VTWs6hw4HZsPy7" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>  
                                <div id="YWQc99mlFU4V6Iff" style={{ zIndex: 48 }}>  
                                    <div id="U2fZH2HWRHJKgTDD" style={{ paddingTop: "70.18181818%", transform: "rotate(0deg)" }}>  
                                        <div id="BVj7w9PlbpIazKOa" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="sdsG0Abebf7rGHOD" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="ku5tMvV3eXGIl6MH" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="LrS4xAwpBbjiMBRd" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <img src={darkRRSSCloud}  alt="darkRRSSCloud"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                                <div id="zLnA5jc6ytxVLU5d" style={{ zIndex: 49 }}>  
                                    <div id="oqyluzM77te95sUu" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>  
                                        <div id="oGOimu98YXb3aqY5" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="Ga0ylQIWqoEkCyK3" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="x95RsaXqStbkS1DI" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="yd0Ptydv21Xel2OE" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <a href={linkTwitter} target="_blank" rel="noreferrer"><img src={twitterIcon} alt="twitter"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  </a>
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                        <div id="catOtTNqbN4IEdvi" style={{ display: "grid", position: "relative", gridArea: "7 / 8 / 10 / 9" }}>  
                            <div id="Ut5G0BwYaVnl4mNV" style={{ display: "grid", position: "relative", gridArea: "2 / 2 / 3 / 3" }}>  
                                <div id="FFQQKEXTL2UUXZKv" style={{ zIndex: 54 }}>  
                                    <div id="V69bT36R5nrd9TWC" style={{ paddingTop: "71.45454545%", transform: "rotate(0deg)" }}>  
                                        <div id="IcyZm6dFIZ44nnnK" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="hPJkwE1BSlrQkqrB" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="LK8KIYg66L6goCXH" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="WRHDjInCUb5qf0yD" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <img src={darkRRSSCloud}  alt="darkRRSSCloud"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                                <div id="aTXzmvQHiSTIt6s1" style={{ zIndex: 55 }}>  
                                    <div id="PKPn6f2yHYdkaTKS" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>  
                                        <div id="Sn3DhGjTEUpmSRsC" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                                            <div id="dbIWCZaTji1cxmAb" style={{ width: "100%", height: "100%", opacity: 1.0 }}>  
                                                <div id="LzjU5hOZNFq4Tvkl" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                                    <div id="TW13P236piEfvs1k" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                                        <a href={linkInstagram} target="_blank" rel="noreferrer"><img src={instagramIcon} alt="instagram"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  </a>
                                                    </div>  
                                                </div>  
                                            </div>  
                                        </div>  
                                    </div>  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
            </div>  
            {/* <div id="qvbahjB2kJdOYR0h" style={{ zIndex: 1 }}>  
                <div id="ZSUpKhS0dz7OMfOr" style={{ paddingTop: "7.411154%", transform: "rotate(0deg)" }}>  
                    <div id="JARoxwPFrM56XILB" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                        <svg id="DXQu0Z97UMdcjfn7" viewBox="0 0 379.4444 28.1212" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>  
                            <g id="A7Z3JcHy9mXaZKHr" style={{ transform: "scale(1, 1)" }}> 
                                <path id="fU1I95ky40z00eSN" d="M379.44444444,0 L379.44444444,19.12121212 L189.72222222,28.12121212 L0,19.12121212 L0,0 L379.44444444,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} /> 
                            </g>  
                        </svg>  
                    </div>  
                </div>  
            </div>  */}
            <div id="D5f3XNohpFkPyLJl" style={{ zIndex: 10 }}>  
                <div id="AKd2qCFLqqsIOJDW" style={{ paddingTop: "112.99435028%", transform: "rotate(15.64420534deg)" }}>  
                    <div id="UseNFTk1CdbnOnj7" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                        <div id="wxRJQwTGhIDw7F67" style={{ width: "100%", height: "100%", opacity: "0.35" }}>  
                            <div id="RIyiKwgOGDfNnQNC" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                <div id="x89BvVeRJunHs6lf" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
            </div>  
            <div id="ojXjXSBOkxn07FNK" style={{ zIndex: 12 }}>  
                <div id="NtnFF0b6FEADol8T" style={{ paddingTop: "112.99435028%", transform: "rotate(-32.40876519deg)" }}>  
                    <div id="Y3sZpH091gVruzaM" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                        <div id="nMiIGWRyc88OSbXd" style={{ width: "100%", height: "100%", opacity: "0.35" }}>  
                            <div id="Fg8Wf5g1Y62e5xFN" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                <div id="NnenxYWUgR32Qd4d" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
            </div>  
            <div id="JojHkPZEyHMJUrpI" style={{ zIndex: 3 }}>  
                <div id="Lukxgod79zcauzHh" style={{ paddingTop: "115.10791367%", transform: "rotate(-14.60953275deg)" }}>  
                    <div id="CgSf4Z7DWnSWxmJ4" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                        <div id="N9GUsWexcOu4Kj96" style={{ width: "100%", height: "100%", opacity: "0.35" }}>  
                            <div id="iN4AJRUN43HT2EWG" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                <div id="bNfJh3euQg3W3AKs" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>  
                                    <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
            </div>  
            <div id="rdXknhawadPwEBnB" style={{ zIndex: 11 }}>  
                <div id="Wfcp70S2Yl7y0KtD" style={{ paddingTop: "112.99435028%", transform: "rotate(15.64420534deg)" }}>  
                    <div id="nyetTvkn64n8Eg4e" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>  
                        <div id="VjINsKbcCbYWVwr7" style={{ width: "100%", height: "100%", opacity: "0.35" }}>  
                            <div id="nN6w47erWnw3JSy6" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>  
                                <div id="EgxSqP0tRt5wtI3Q" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />  
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
            </div>  
           <div id="bq8HykN5Ixvz43UY" style={{ zIndex: 19 }}>
    <div id="ILBzZXPkZljRn091" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
        <div id="UDcjNyIAHpmkQreF" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
            <p id="AldebI0dWqyyP8mo" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", marginLeft: "0em", lineHeight: "1.38934312em", textAlign: "center", textTransform: "none", letterSpacing: "0em" }}> <span id="VqxVagnuxROoa7LV" style={{ color: "#fefefe" }}> battle music </span>
                <br /> </p>
        </div>
    </div>
</div>
<div id="KIb8nUdUIJ3t5EwH" style={{ zIndex: 8 }}>
    <div id="tPnjREf1XgZFZlbX" style={{ paddingTop: "115.10791367%", transform: "rotate(14.94247992deg)" }}>
        <div id="aiRgjyFkoyD8A70V" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="XcRIY2szImZIhFN4" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="QsqU2h98IBouaHjN" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="lj73oQayd1t7f1rx" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                        <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="PJ62bmV63gBXEHTT" style={{ zIndex: 4 }}>
    <div id="QC9bhEiWtrstXSFX" style={{ paddingTop: "115.10791367%", transform: "rotate(-28.41297634deg)" }}>
        <div id="ReeFa5yuHB9NeQT0" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="MfHbqkHxEPeIjEtl" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="q9wzlKAjZDNKV6zX" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="htCU2NGefA3m4azP" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                    <img src={musicNotes}  alt="musicNotes"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="uOTDG4HHhd7vBDz6" style={{ zIndex: 14 }}>
    <div id="qq8oakeQt15ndKg0" style={{ paddingTop: "112.99435028%", transform: "rotate(32.80374705deg)" }}>
        <div id="YjqBOqA19gNTtKPw" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="jpX1dfFrCMBROvdb" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="pdjuXayuk4zsGkYE" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="FuPqTsuJhMaBpXYO" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                        <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="ykm8RxMu4W3gqfpQ" style={{ zIndex: 7 }}>
    <div id="pPc7ArbRV6A3o1F2" style={{ paddingTop: "115.10791367%", transform: "rotate(-26.20360907deg)" }}>
        <div id="UIsDRzHo8YYI20j9" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="vZ4u1AYfvyxWNY85" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="HqK3N2eSM6ZtuWZL" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="Ig8oxWwPrQEveMLC" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                        <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="rxtzK3wu4VaW3QyJ">
    <div id="e0A2L65V25MXHBs6" style={{ display: "grid", position: "relative" }}>
        <div id="MAgqkfcBmzhBw2PO" style={{ zIndex: 15, pointerEvents: "none" }}>
            <div id="UqmeCA64NE1ucfVZ" style={{ paddingTop: "105.55842018%", transform: "rotate(0deg)" }}>
                <div id="i1GnME2WRXcqsp52" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="Hdz0Sw4g89eqgzSC" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="wEYDd9G5qsfzTIsk" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="jbzdh8URceOgXOf1" style={{ width: "calc(105.55842018% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                <img src={battleMusic} alt="battleMusic" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "52.63286442% 50%", transform: "translate(-52.63286442%, -50%) rotate(0deg)" }} /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="B4PLkLTOQm5i6ian" style={{ zIndex: 18 }}>
            <div id="mVHXLwD6qu2KXotP" style={{ paddingTop: "90.10989011%", transform: "rotate(0deg)" }}>
                <div id="TkTeVkJtk4eHSgnf" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <a id="VMLNs0ROVkozZ70e" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=957zl0ZQS5k"  style={{ pointerEvents: "all" }} data-interstitial-link="">
                        <div id="fJKJsn82yx4Kr6I9" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="lb51HhMaPV9bx9yL" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="MlLlN4bSwJmC4W8z" style={{width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                                    <img src={yellowPlay} alt="yellowPlay" loading="lazy" style={{width: "100%",height: "100%",display: "block",objectFit: "cover",objectPosition: "50% 50%",transform: "translate(-50%, -50%) rotate(0deg)"}} />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div id="q3qRIjTVfCrFd8HG" style={{ zIndex: 23 }}>
            <div id="Bp3hd2Qi7bZjmW9W" style={{ paddingTop: "90.10989011%", transform: "rotate(0deg)" }}>
                <div id="OnUiVQyRTbajZsDK" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <a id="SocfF9uZUcUyo2xs" target="_blank" rel="noreferrer" href="https://youtu.be/QYi4qZnTlEE" style={{ pointerEvents: "all" }} data-interstitial-link="">
                        <div id="fSJwqycbzw9bEPaV" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                            <div id="HGtFVA0wVf2KAJjw" style={{ transform: "scale(1, 1)", width: "100%",height: "100%", overflow: "hidden", position: "relative" }}>
                                <div id="QIEg3sq1Q5tZMlvB" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                    <img src={yellowPlay} alt="yellowPlay" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)"}} />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        
        <div id="ZmgxPMKPaHmvbX0W" style={{ zIndex: 108 }}>
            <div id="n7J9PSRGYLjPLbfH" style={{ paddingTop: "90.10989011%", transform: "rotate(0deg)" }}>
                <div id="ZAZehREvjBWIXCxW" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="KZyIwPtUPk4PsUKa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="nPB6dbJQdOj9LwCx" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="h32mkpPLCHOJD7gi" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                <img src={yellowPlay} alt="yellowPlay" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="V41LjqeJZYdppplQ" style={{ zIndex: 109 }}>
            <div id="wWLIKTIrfdhsaF0K" style={{ paddingTop: "90.10989011%", transform: "rotate(0deg)" }}>
                <div id="aDjjGx6sCWVnFtU6" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="JaEJF8vTNzQG5M1Z" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="K6WmBwIYpXF3zEnt" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="t2pvRN2i4w9DXgy6" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                <img src={yellowPlay} alt="yellowPlay" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="urZ9QxhgB2zjfpAV" style={{ zIndex: 110 }}>
            <div id="dwAtjXdYLfUYzdpj" style={{ paddingTop: "90.10989011%", transform: "rotate(0deg)" }}>
                <div id="cpZrjtoNj0XSzlZx" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="t8ZKBLHqJenVUflV" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="ZyBEgnvdHg8wOO64" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="X3PQ0wTznn0eCDDL" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1,  }}>
                                <img src={yellowPlay} alt="yellowPlay" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
                              

    </div>
</div>
<div id="emvMRPf8JpuoZxOO" style={{ zIndex: 5 }}>
    <div id="vpf3jCAE7WNVFovg" style={{ paddingTop: "115.10791367%", transform: "rotate(14.94247992deg)" }}>
        <div id="vfeuKZzFKGBh1N1B" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="tnBbgUfxDxftf1jI" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="SjENyi5izsOptNwx" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="emwkeUI5d8cAjQeQ" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                        <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="jE09JGaHd7kjDYfd" style={{ zIndex: 6 }}>
    <div id="dFDbG2mnEvjaVh9l" style={{ paddingTop: "115.10791367%", transform: "rotate(14.94247992deg)" }}>
        <div id="SDAAkmbBfsxeDYhL" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="YB0B2Yo5jCCDaVmf" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="wai9AcrvhBwsjVUC" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="q8ImLpGS0tx5pY0y" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                        <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="bxdV1DHHfU9ZsuQc" style={{ zIndex: 9 }}>
    <div id="yf3baptELuRgND3d" style={{ paddingTop: "115.10791367%", transform: "rotate(2.76915887deg)" }}>
        <div id="G02kvKYwkbOcaAbA" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="toxfkFauG8XVEY4z" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="PuiBzhtKvBSgIav9" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="tZmeHPdz49O3w060" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                        <img src={musicNotes}  alt="musicNotes" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="zcVtotdKRYhL4mhp" style={{ zIndex: 13 }}>
    <div id="Xma7UxTJaC2tf0Fm" style={{ paddingTop: "112.99435028%", transform: "rotate(15.64420534deg)" }}>
        <div id="mUas6fgDPulENuRT" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
            <div id="lHAFcKysPcfoHuIZ" style={{ width: "100%", height: "100%", opacity: "0.35" }}>
                <div id="xc7uGKyXmaPgO2Av" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                    <div id="lsG57GgUHd2WC7z9" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}>
                        <img src={chefIcon} alt="chefIcon"  loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="mKnYKG32IA8doBfS">
    <div id="zqCMsLr9JqjJLFWL" style={{ display: "grid", position: "relative" }}>
        <div id="mgJLMtDYTvGhGjW0" style={{ zIndex: 2 }}>
            <div id="kVlasrfOKEQZJdi6" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(-180deg)" }}>
                <svg id="QR9d4DFoUHiAnfDA" viewBox="0 0 213.3333 31.7584" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                    <g id="QFBauLiCi4zBCcVH" style={{ transform: "scale(1, 1)" }}>
                        <path id="FKApzMibWy6yXC1v" d="M213.33333333,0 L213.33333333,22.75844287 L106.66666667,31.75844287 L0,22.75844287 L0,0 L213.33333333,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                    </g>
                </svg>
                <svg id="Tqk7ueKTSXRuiaZe" viewBox="0 0 118.779 49.3924" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                    <g id="BWQI92rtvNFrthSt" style={{ transform: "scale(1, 1)" }}>
                        <path id="isLSc6f4eMSRsPae" d="M118.77899878,0 L118.77899878,40.39242513 L59.38949939,49.39242513 L0,40.39242513 L0,0 L118.77899878,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                    </g>
                </svg>
                <svg id="n8wNzspPnrmOD6AJ" viewBox="0 0 110.0269 50.3494" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                    <g id="GuTPjfvnWSho9Hhn" style={{ transform: "scale(1, 1)" }}>
                        <path id="pcvp3V5EFxcQUXjk" d="M110.02686203,0 L110.02686203,41.34941018 L55.01343101,50.34941018 L0,41.34941018 L0,0 L110.02686203,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                    </g>
                </svg>
                <svg id="fCnhW24xEPFv81iL" viewBox="0 0 70.0171 44.6913" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                    <g id="xhwtK593b7zwatdh" style={{ transform: "scale(1, 1)" }}>
                        <path id="H5be2AaTKfgSTNBM" d="M70.01709402,0 L70.01709402,35.69133107 L35.00854701,44.69133107 L0,35.69133107 L0,0 L70.01709402,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                    </g>
                </svg>
                <svg id="fkBIRv7JmkYBufpH" viewBox="0 0 53.6068 42.3133" preserveAspectRatio="none" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%" }}>
                    <g id="MoACD3duAPRbWVQm" style={{ transform: "scale(1, 1)" }}>
                        <path id="CKnuTLE8CbAejGP5" d="M53.60683761,0 L53.60683761,33.31334694 L26.8034188,42.31334694 L0,33.31334694 L0,0 L53.60683761,0 Z" style={{ fill: "#207ce4", opacity: 1.0 }} />
                    </g>
                </svg>
            </div>
        </div>
        <div id="v79WJnSP5ixGnQuS" style={{ zIndex: 16 }}>
            <div id="bFxrOGkZqoAaizor" style={{ paddingTop: "48.69808561%", transform: "rotate(0deg)" }}>
                <div id="dqRubOOUVz7Xn1lA" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="F7yvAUTjFY3OLLU7" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="oMNd3e00oFkSIy4i" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="OiWrxLxm9P2A8WeV" style={{ width: "calc(137.47292659% * max(1, var(--scale-fill, 1)))", height: "calc(113.80072304% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1}}> 
                                <img src={flan} alt="flan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 47.2438418%", transform: "translate(-50%, -47.2438418%) rotate(0deg)" }} /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="GurYmQIGgbCrZXck" style={{ zIndex: 17 }}>
            <div id="aEqrxqTvlqDGYAVX" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                <div id="pwZCnXBvPn897GJz" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <div id="Yny2yFqFVW9Dm2Bn" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                        <div id="l1ySgooVuu4P08bK" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                            <div id="yk6Mg9fUDlYI1mYp" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1.0 }}> 
                                <img src={copyRight} alt="Copyright" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%", transform: "translate(-50%, -50%) rotate(0deg)" }} /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="FF35s582652mWWdK" style={{ zIndex: 21 }}>
            <div id="MqYkVXKK3GzrL2Pq" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                <div id="mYNnueTW5GtR6Exx" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                    <p id="enJ5Kv6get8UZZ3X" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", lineHeight: "1.36518771em" }}> <span id="MmBSEj8i8l8FCBtZ" style={{ color: "#2b384c" }}> 2024 FLAN, FLANCOIN </span> </p>
                    <p id="IZHhbp6x3GAUMujw" style={{ color: "#2b384c", fontFamily: "CC-Maladroit", marginLeft: "0em", lineHeight: "1.36518771em", textTransform: "none", letterSpacing: "0em", whiteSpace: "pre" }}>
                        <br /> </p>
                </div>
            </div>
        </div>
        <div id="L0c8XIbGGObdOeQO" style={{ zIndex: 20 }}>
            <div id="ETao3VKXrVIlg6BF" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                <div id="dGJ6rrzK9yPBY4xJ" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                    <p id="Ua8K7JBXTQ5rs6BO" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.36518771em" }}> 
                        <span id="eihNn2C1Ck1DzeOK" style={{ color: "#fefefe" }}> DISCLAIMER </span> 
                    </p>
                    <p id="Yediz0XXnMHzMAen" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", lineHeight: "1.36518771em" }}> 
                        <span id="E6fF72Lyat8bP7bc" style={{ color: "#fefefe" }}> $FLAN WAS MADE FOR A FAN AND HAS NO VALUE. DON’T RISK MONEY YOU ARE AFRAID OF LOSING. THE PRICE MAY GO UP OR IT MAY GO DOWN. WE ARE NOT RESPONSIBLE FOR THE PRICE OF THE TOKEN. </span>
                        <br /> 
                    </p>
                    <p id="dV9vr0ecYNsBCU7p" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", marginLeft: "0em", lineHeight: "1.36518771em", textTransform: "none", letterSpacing: "0em", whiteSpace: "pre" }}>
                        <br /> 
                    </p>
                </div>
            </div>
        </div>
        <div id="yQLT8d34oyNi4l6z" style={{ zIndex: 22 }}>
            <div id="WzbcV0zi7kFU2YSK" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                <div id="gusLrDdXNQeaahZt" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                    <p id="Xl0io9sXbF3lgYN9" style={{ color: "#fefefe", fontFamily: "CC-Maladroit", marginLeft: "0em", lineHeight: "1.36518771em", textTransform: "none", letterSpacing: "0em" }}> 
                        <span id="T1O0Fi7IX1T43SfU" style={{ color: "#fefefe" }}> $FLAN ON SOLANA. </span>
                        <br /> 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    </section>

      </div>
  </div>
  );
}
  
export default root;