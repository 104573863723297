
import './App.css';
import Root from './home/index.js';
import AvatarConstructor from './cookFlan/index.js';
import Eurocopa from './eurocopa/index.js';
import Index from './index.js';
import {
  BrowserRouter as Router,Routes,Route,} from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        
      </div>
      <Routes>
        <Route path="/cook" element={<AvatarConstructor/>} />
        <Route path="/euroflan" element={<Eurocopa/>} />
        <Route path="/home" element={<Root/>} />
        <Route path="/" element={<Index/>} />
      </Routes>
      
    </Router>
    
  );
}

export default App;
