import './index.css';
import Logo from '../assets/images/flanLogo.webp';
import LogoMinecraft from '../assets/images/FLANCRAFT.png';
import { useNavigate } from "react-router-dom";
function Index() { 
    const navigate = useNavigate();
    const handleHome = () => {
        navigate("/home");
    }
    return (
        <div id="home">
            <div className="row center">
                <div className="col">
                    <img src={Logo} id="logo"/><span id="title">FLANCOIN VERSE</span>
                </div>
            </div>
            <div className="row center targets">
                <div className="col"/>
                <div className="col"/>
                <div className="col">
                    <img src={Logo} id="logoTargetFlancoin" onClick={handleHome}/>
                </div>
                <div className="col"/>
                <div className="col">
                    <a href="https://flancraft.com" target='_blank'>
                        <img src={LogoMinecraft} id="logoTargetFlancraft"/>
                    </a>
                </div>
                <div className="col"/>
                <div className="col"/>
            </div>
            <div className="row center">
                <div className="col"/>
                <div className="col"/>
                <div className="col">
                    <span id="titleTarget"  onClick={handleHome}>FLANCOIN</span>
                </div>
                <div className="col"/>
                <div className="col">
                    <a href="https://flancraft.com" target='_blank'>
                        <span id="titleTarget">FLANCRAFT</span>
                    </a>
                </div>
                <div className="col"/>
                <div className="col"/>
            </div>
        </div>
    )
}

export default Index;