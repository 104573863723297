import React, { useState, useEffect } from 'react';
//import LoadingOverlay from 'react-loading-overlay';
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { SolanaConnect } from "solana-connect";
import Web3Methods from './Web3Methods.js';

import './index.css';

import flanFootball from './images/flanfootball.webp';
import euroFlan from './images/euroflan.webp';
import background from './images/background.webp';
import connect from './images/connect.png';

import spain from './images/spain.webp';
import austria from './images/austria.png';
import denmark from './images/denmark.png';
import france from './images/france.png';
import germany from './images/germany.png';
import england from './images/england.png';
import italy from './images/italy.png';
import portugal from './images/portugal.png';
import switzerland from './images/Switzerland.png';
import Belgium from './images/Belgium.png';
// import Czechia from './images/Czechia.png';
// import croatia from './images/croatia.png';
import Netherlands from './images/Netherlands.png';
import Turkey from './images/Turkey.png';
// import ukraine from './images/ukraine.png';
// import hungary from './images/hungary.png';
import rumania from './images/rumania.png';
import slovakia from './images/slovakia.png';
import slovenia from './images/slovenia.png';
import georgia from './images/georgia.png';
//import eurocopaDB from './mysql';

const Eurocopa = () => {
    const [teamSelected, setTeamSelected] = useState(null);
    const [solConnect, setSolConnect] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [balance, setBalance] = useState(null);
    const [idEquipo, setIdEquipo] = useState(0);
    const [overlay, setOverlay] = useState(true);
    const [votedPreviously, setVotedPreviously] = useState(false);
    const [message, setMessage] = useState("No more voting is allowed for this giveaway. If you are among the winners, you will receive your reward when the Euro Cup ends");
    const [loading, setLoading] = useState(false);
    let RPCMethods = Web3Methods.getInstance();
    const getFlanPrice = async() => {
        let result = 0;
        await axios.get("https://price.jup.ag/v6/price?ids=FLAN").then((response) => {
          result =  response.data.data.FLAN.price;
        });
        return result;
      }

      useEffect(() => {
        if(!solConnect){
          let tmp = new SolanaConnect();
          tmp.onWalletChange(async (adapter) => {
              if(adapter){
                let cartera = adapter.publicKey.toString();
                setWallet(cartera);
                let flanBalance = await RPCMethods.getTokenAccountsByOwner(cartera);
                setBalance(flanBalance);
                let price = await getFlanPrice();
                if(flanBalance * price > 250){
                    getVotedTeam(cartera).then(() => {
                        setOverlay(false);
                    });
                }else{
                    // getVotedTeam(cartera).then(() => {
                    //     setOverlay(false);
                    // });
                    setMessage("YOU NEED TO HOLD AT LEAST $250 FLAN TO VOTE FOR A TEAM. YOU HAVE $" + Number(flanBalance * price).toFixed(2).toString());
                }
              }
            }
          );
          
          tmp.onVisibilityChange((isOpen) => {
            //console.log("menu visible:", isOpen);
          });
    
          setSolConnect(tmp);
        }
      }, [])
      
      
    
      const openConnect = () => {
        solConnect.openMenu();
      }
    
    useEffect(() => {
        if(idEquipo > 0){
            editTeamHover(idEquipo);
        }
        
    }, [idEquipo]);

    const getVotedTeam = async (cartera) => {
        await axios.get(`https://bckflancoin.netlify.app/.netlify/functions/server/vote/${cartera}`).then((response) => {
            if(response.data.length > 0){
                setVotedPreviously(true);
                setIdEquipo(response.data[0].idEquipo);
                setTeamSelected(response.data[0].equipoSeleccionado);
            }
          });
    }
    const handleSetTeamSelected = (team, id) => {
        if(!votedPreviously){
            setTeamSelected(team);
            editTeamHover(id);
        }
    }
    const votedTeam = async () => {
        setLoading(true);
        var body = {
            cartera: wallet,
            tokens: balance,
            equipoSeleccionado: teamSelected,
            idEquipo: idEquipo
        };
        await axios.post(`https://bckflancoin.netlify.app/.netlify/functions/server/registerVote`, body).then((response) => {
            if(response.data.affectedRows > 0){
                withReactContent(Swal).fire({
                    iconHtml: '<img src='+flanFootball+' id="flanSwalFootBall"/>',
                    text: "Vote for " + teamSelected + " registered!"
                  });
            }
          });
        setVotedPreviously(true);
        setLoading(false);
    }
    useEffect( () => {
        if(teamSelected && !votedPreviously){
            withReactContent(Swal).fire({
                iconHtml: '<img src='+flanFootball+' id="flanSwalFootBall"/>',
                text: "You are going to vote for " + teamSelected
              });
        }
        
    }, [teamSelected])


    const editTeamHover = (item) => {
        setIdEquipo(item);
       for(var i = 1; i <= 16; i++){
        document.getElementById("team"+i).classList.remove("equipoSelected");
       }
       document.getElementById("team"+item).classList.add("equipoSelected");
    }
    
    const vote = async () => {
        if(!teamSelected){
            withReactContent(Swal).fire({
                iconHtml: '<img src='+flanFootball+' id="flanSwal"/>',
                text: "You need to select a team to vote"
              });
        }else{
            await votedTeam();
        }
    }

    return (
        <div id="root">
            {/* <LoadingOverlay
                active = {loading}
                spinner
                text="loading..."> */}
            <section id="RTcwCMWclk3MRCDd" style={{ position: "relative", overflow: "hidden", display: "grid", alignItems: "center", gridTemplateColumns: "auto 100rem auto", zIndex: 0 }}>
                <div id="Y3WWTY8BQm1vyHbR" style={{ gridArea: "1 / 1 / 2 / 4", display: "grid", position: "absolute", minHeight: "100%", minWidth: "100%" }}>
                    <div id="dABC8rQ2iontLfOJ" style={{ zIndex: 0 }}>
                        <div id="e2BBu9UMirwPSH0t" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="mtytvzDhrzxEkskC" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                <div id="J5Q5RY9ScMiGfYxh" style={{ backgroundColor: "#005caa", opacity: 1.0, transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }} />
                            </div>
                        </div>
                    </div>
                    <div id="QkGkFZUSVFzhBRai" style={{ zIndex: 39, left: "max(0px, calc(50% - 106.91017979px))", right: "max(0px, calc(50% - 106.91017979px))", top: "max(0%, calc(50% - 92.75498167px))", position: "absolute" }}>
                        <div id="RTtI77xIeYpkzhX9" style={{ paddingTop: "86.75972845%", transform: "rotate(-59.76881433deg)" }}>
                            <div id="RNsJGBidsJM6hfEi" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="DSDF6CKcDz83NpEK" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="gcZcPAvzgaY98HAn" style={{ transform: "scale(-1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="wwPt8RgdjUdzU3df" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(115.26084946% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%",  opacity: 1, animation:
                                        "auto ease 0s 1 normal none running none" }}>
                                            <img src={flanFootball} alt="flanfootball" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 54.11629766%", transform: "translate(-50%, -54.11629766%) rotate(0deg)" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="bEWljcBIfhyrqGdN" style={{ display: "grid", position: "relative", gridArea: "1 / 2 / 2 / 3" }}>
                    <div id="xnIpTDqFBm6biK62" style={{ zIndex: 22 }}>
                        <div id="gkZ9Q4ctJHL4diDW" style={{ paddingTop: "143.07565931%", transform: "rotate(0deg)" }}>
                            <div id="t5IOZIgiqeCiiCRc" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                <div id="THwaFF4txHW2htln" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                    <div id="trT7jht51EPTWRO6" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                        <div id="p7HFJKcVKiXv7ATZ" style={{ width: "calc(272.52506535% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute", top: "50%", left: "50%", opacity: 1, animation:
                                        "auto ease 0s 1 normal none running none" }}>
                                            <img src={euroFlan} alt="euroflan" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "49.94242021% 50%", transform: "translate(-49.94242021%, -50%) rotate(0deg)" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="c6yUD6N9D2JCBzvw">
                        <div id="qIURWGmPfrVoSX7V" style={{ display: "grid", position: "relative", gridArea: "3 / 6 / 7 / 8" }}>
                            <div id="HaqqEWrcPC3pGXgI" style={{ zIndex: 21 }}>
                                <div id="aTbKSXVZtbayXKhk" style={{ paddingTop: "18.9469016%", transform: "rotate(0deg)" }}>
                                    <div id="HKxYKqSvVIPnIa3P" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <svg id="S6jFGxdeGtNSJppq" viewBox="0 0 2582.4587 489.2959" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                            <g id="POrJwDaEX2K7T3eP" style={{ transform: "scale(1, 1)" }}>
                                                <path id="cBAbsxIJlFxNpRIN" d="M2572.65865804,486.79589881 L9.80000019,486.79589881 C5.80000019,486.79589881 2.5,483.49589576 2.5,479.49589576 L2.5,9.80000019 C2.5,5.80000019 5.80000019,2.5 9.80000019,2.5 L2572.55866719,2.5 C2576.55866719,2.5 2579.85867024,5.80000019 2579.85867024,9.80000019 L2579.85867024,479.39590491 C2579.95866109,483.49589576 2576.65865804,486.79589881 2572.65865804,486.79589881 Z"
                                                style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                <path id="DuyBe4gG20U6nHtF" d="M2572.65865804,4.69999981 C2575.45866109,4.69999981 2577.75866414,7 2577.75866414,9.80000019 L2577.75866414,479.49591102 C2577.75866414,482.29591407 2575.45866109,484.59590186 2572.65865804,484.59590186 L9.80000019,484.59590186 C7.00000048,484.59590186 4.70000029,482.29591407 4.70000029,479.49591102 L4.70000029,9.80000019 C4.69999981,7 7,4.69999981 9.80000019,4.69999981 L2572.65867329,4.69999981 M2572.65867329,0 L9.80000019,0 C4.4000001,0 0,4.4000001 0,9.80000019 L0,479.49591102 C0,484.89590491 4.4000001,489.29591407 9.80000019,489.29591407 L2572.65867329,489.29591407 C2578.05866719,489.29591407 2582.45867635,484.89590491 2582.45867635,479.49591102 L2582.45867635,9.80000019 C2582.45867635,4.4000001 2578.05866719,0 2572.65867329,0 Z"
                                                style={{ fill: "#272727", opacity: 1.0 }} />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div id="QAfBINpRP0HrYmYX" style={{ zIndex: 20 }}>
                                <div id="GpJMzRbyybSyC8gk" style={{ paddingTop: "19.55939519%", transform: "rotate(0deg)" }}>
                                    <div id="XxjRsLlF2pB2Ad1f" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                        <svg id="zALNlClT5yJBLTXP" viewBox="0 0 2620.3453 512.5237" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                            <g id="nKpRuVZEXgVVxb7M" style={{ transform: "scale(1, 1)" }}>
                                                <path id="KuXAfPEcchBYOLlg" d="M2610.54528775,510.02368833 L9.80000019,510.02368833 C5.80000019,510.02368833 2.5,506.72368528 2.5,502.72368528 L2.5,9.80000019 C2.5,5.80000019 5.80000019,2.5 9.80000019,2.5 L2610.4452969,2.5 C2614.4452969,2.5 2617.74529995,5.80000019 2617.74529995,9.80000019 L2617.74529995,502.62369443 C2617.8452908,506.72368528 2614.54528775,510.02368833 2610.54528775,510.02368833 Z"
                                                style={{ fill: "#272727", opacity: 1.0 }} />
                                                <path id="GsuoB1Pg2MHatuie" d="M2610.54528775,4.69999981 C2613.3452908,4.69999981 2615.64529385,7 2615.64529385,9.80000019 L2615.64529385,502.72370054 C2615.64529385,505.52370359 2613.3452908,507.82369138 2610.54528775,507.82369138 L9.80000019,507.82369138 C7.00000048,507.82369138 4.70000029,505.52370359 4.70000029,502.72370054 L4.70000029,9.80000019 C4.69999981,7 7,4.69999981 9.80000019,4.69999981 L2610.54530301,4.69999981 M2610.54530301,0 L9.80000019,0 C4.4000001,0 0,4.4000001 0,9.80000019 L0,502.72370054 C0,508.12369443 4.4000001,512.52370359 9.80000019,512.52370359 L2610.54530301,512.52370359 C2615.9452969,512.52370359 2620.34530606,508.12369443 2620.34530606,502.72370054 L2620.34530606,9.80000019 C2620.34530606,4.4000001 2615.9452969,0 2610.54530301,0 Z"
                                                style={{ fill: "#272727", opacity: 1.0 }} />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Qhe2CTpXpib6WFkE" style={{ zIndex: 40 }}>
                        <div id="iceYcKJDK7cH7bUb" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                            <div id="lPrFdVNdtgd4gNdR" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                <p id="it711YlKDZH7eQkc" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.399986em", textAlign: "center", textShadow: "0em 0.0375em 0.2154375em rgba(0, 0, 0, 0.451500)" }}>
                                    <span id="Z7IKpqLKaJrhc11q" style={{ color: "#000000" }}>
                        In EuroFlan 2024, predict the Eurocup winner among 16 teams.
                    </span>
                                </p>
                                <p id="bkwm57JJPhJEO3VS" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.399986em", textAlign: "center", textShadow: "0em 0.0375em 0.2154375em rgba(0, 0, 0, 0.451500)" }}>
                                    <span id="LJ9hFxXen3fu6Oov" style={{ color: "#000000" }}>
                        Win a share of 1000$ in $FLAN.
                    </span>
                                </p>
                                <p id="rRujWNgHkYl06GQe" style={{ color: "#000000", fontFamily: "CC-Maladroit", lineHeight: "1.399986em", textAlign: "center", textShadow: "0em 0.0375em 0.2154375em rgba(0, 0, 0, 0.451500)" }}>
                                    <span id="TN48DyPKWJiMoAPo" style={{ color: "#000000" }}>
                        It's completely free, you just need to hold 250$ in $FLAN in
                        your wallet. Choose wisely, vote once. Show your football
                        passion, register now!
                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    { overlay && (
                        <div style={{display: "grid", position: "relative", gridArea: "8 / 3 / 21 / 9"}}>
                            <h1 style={{fontSize: "5.5em", zIndex: 20, textAlign: "center", color: "#6684C1"}}>
                                {message}
                                <br/>
                            </h1>
                            
                        </div>
                    )}
                     {!overlay && (
                    <div id="SIwmt04PWnFqNCU2">
                        <div id="IC6fZPeB4eD53gH7" style={{ display: "grid", position: "relative", gridArea: "5 / 3 / 9 / 9" }}>
                            <div id="soPmunE6zCpSZqrr" style={{ zIndex: 1 }}>
                                <div id="wBRf97bz9ezCqvQU" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                    <div id="NNTxTyxupb68ucGA" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                        <div id="Ua4e88b4lDaZTCB1" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                            <div id="uS4ccWuDyxE2PX5b" style={{ width: "calc(100% * max(1, var(--scale-fill, 1)))", height: "calc(100% / min(1, var(--scale-fill, 1)))", position: "absolute",  opacity: 1, animation:
                                            "auto ease 0s 1 normal none running none" }}>
                                                <img src={background} alt="background" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {votedPreviously && (<h1 id="" style={{zIndex:50, display: "grid", position: "relative", gridArea: "3 / 17 / 4 / 51", color: "white", fontWeight: 900, gridTemplateColumns: "none", gridTtemplateRows: "none", textAlign: "center" }}> you can't vote again, you have already voted for {teamSelected} </h1>)}
                            {!votedPreviously && (<div id="mfmwxNBjqSAskjiQ" style={{ display: "grid", position: "relative", gridArea: "3 / 31 / 4 / 40" }}>
                                <div id="GF6exAkeOI4Z6WGq" style={{ zIndex: 2 }}>
                                    <div id="hmZ3bWgtNBeG1ZD1" style={{ paddingTop: "24.67282632%", transform: "rotate(0deg)" }}>
                                        <div id="BqK0VZbHXXA8MdNq" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <svg id="jegTDM9hYVrWXqKv" viewBox="0 0 133.802 33.0127" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                <g id="Ca7Lft0VhQDtOR6p" style={{ transform: "scale(1, 1)" }}>
                                                    <path id="ucijpq6EfBMPLjh9" d="M126.10196416,29.61272891 L4.5,29.61272891 C4.9000001,31.01272281 6.20000029,32.01272281 7.70000029,32.01272281 L129.40196721,32.01272281 C131.30196874,32.01272281 132.80196874,30.51272281 132.80196874,28.61272891 L132.80196874,7.80000019 C132.80196874,6.30000019 131.80196874,5.10000038 130.50196569,4.60000038 L130.50196569,25.21272739 C130.50196569,27.61272891 128.50196569,29.61272891 126.10196416,29.61272891 Z"
                                                    style={{ fill: "#272727", opacity: 1.0 }} />
                                                    <path id="RRcP0mesj035oVTy" d="M4.4000001,28.61272891 L126.10196416,28.61272891 C128.00196569,28.61272891 129.50196569,27.11272891 129.50196569,25.21272739 L129.50196569,4.4000001 C129.50196569,2.50000024 128.00196569,1 126.10196416,1 L4.4000001,1 C2.5,1 1,2.5 1,4.4000001 L1,25.21272739 C1,27.11272891 2.5,28.61272891 4.4000001,28.61272891 Z"
                                                    style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    <path id="HF20azuYL3SlCws4" d="M130.40195959,3.5 C130.00196569,1.5 128.20196264,0 126.10195653,0 L4.4000001,0 C2,0 0,2 0,4.4000001 L0,25.21272739 C0,27.31272586 1.39999998,29.01272281 3.4000001,29.51272281 C3.80000019,31.51272281 5.60000038,33.01272281 7.70000029,33.01272281 L129.40196721,33.01272281 C131.80196874,33.01272281 133.80196874,31.01272281 133.80196874,28.61272891 L133.80196874,7.80000019 C133.80196874,5.69999981 132.40195959,4 130.40195959,3.5 Z M1,25.21272739 L1,4.4000001 C1,2.5 2.5,1 4.4000001,1 L126.10196416,1 C128.00196569,1 129.50196569,2.5 129.50196569,4.4000001 L129.50196569,25.21272739 C129.50196569,27.11272891 128.00196569,28.61272891 126.10196416,28.61272891 L4.4000001,28.61272891 C2.5,28.61272891 1,27.11272891 1,25.21272739 Z M132.80196874,28.61272891 C132.80196874,30.51272281 131.30196874,32.01272281 129.40196721,32.01272281 L7.69999981,32.01272281 C6.19999981,32.01272281 4.9000001,31.01272281 4.5,29.61272891 L126.10196416,29.61272891 C128.50196569,29.61272891 130.50196569,27.61272891 130.50196569,25.21272739 L130.50196569,4.5999999 C131.80196874,5.0999999 132.80196874,6.30000019 132.80196874,7.80000019 L132.80196874,28.61272891 Z"
                                                    style={{ fill: "#272727", opacity: 1.0 }} />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div id="snq3KBNhZvU8DsP1" style={{ zIndex: 41 }}>
                                    <div id="QyIcYMGqBUPETTYw" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                        <div id="FuL9VTBM8CsJ7Pop" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                            <p id="Nk2EOxY6Y3Cfj9C9" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "0.97018343em", textAlign: "center", letterSpacing: "0em" }}>
                                                {!loading && (<span id="ElAXLWc4kaJdeZb7" onClick={() => vote()} style={{ cursor: "pointer", color: "#272727", fontWeight: 900 }}> VOTE </span>)}
                                                {loading && (<span id="ElAXLWc4kaJdeZb7"  style={{ color: "#272727", fontWeight: 900, fontSize: "0.8em" }}> LOADING </span>)}
                                                <br />
                                                
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                                <div id="OjVeEpWxCuCUNwDE" style={{ display: "grid", position: "relative", gridArea: "5 / 5 / 21 / 9" }}>
                                    <div id="owroD4zS3mduyA8Q" style={{ zIndex: 23 }}>
                                        <div id="eaezUF4OiuXOBn2D" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="iugqaU866N3Ufcyo" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="vB5HjOHYtgFaE1LL" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="EDclSaqVVzYdqTRn" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="HdSdQCOb0kfe6RbT" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="QX3lE0DNvGB06ght" style={{ zIndex: 42 }}>
                                        <div id="OyP1aNlVgCk62arK" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="J4PnQvrfAzaDzyDT" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="auCrv3bGRt1xiZmH" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="Idjppj3WPg1pHEuP" style={{ color: "#272727" }}> SPAIN </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="xc9Rq3BFFSpcETwI" style={{ display: "grid", position: "relative", gridArea: "6 / 11 / 22 / 17" }}>
                                    <div id="j5SLvftUE0njwZML" style={{ zIndex: 24 }}>
                                        <div id="GJ3UeQd75NAxDihQ" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="by0eeeTSKU63zM7H" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="ovPFaQ1GI5mMTUvp" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="vJ873SyDx0PzNBkL" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="sScaYunPIP7L2vBy" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ZMELAucqVAo15Z1o" style={{ zIndex: 43 }}>
                                        <div id="LdMRsiiVE2wLDgC8" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="P8AqJXHaGfsoxIuh" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="Y6bAGsA4fH0ZAbu2" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="HfVAbBsFy2L3DJrv" style={{ color: "#272727", fontSize: "0.8em" }}> AUSTRIA </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="JkjF3FC6E15bWDDX" style={{ display: "grid", position: "relative", gridArea: "7 / 19 / 23 / 25" }}>
                                    <div id="SorzGYMIpYKpceQO" style={{ zIndex: 25 }}>
                                        <div id="UqgVqHthHU0XNfPQ" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="uiGMQv0q6dIqxTGr" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="hASPTjXe3RiuTqdN" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="qaosY7Y9tYIyIEMH" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="dZCQav3mdfqgiptT" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="uo2b2lCTbSS4ekWN" style={{ zIndex: 44 }}>
                                        <div id="Mnj35jfX4rxJjCsm" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="AYGAFssk1PYb9apM" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="obpzfxIhRVrXN1RJ" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="VQMeQOL6b1Z2UiZz" style={{ color: "#272727", fontSize: "0.75em" }}> DENMARK </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="HJhaPBRcKiADrblJ" style={{ display: "grid", position: "relative", gridArea: "8 / 27 / 24 / 34" }}>
                                    <div id="cF47Vgixxm4tBCV7" style={{ zIndex: 26 }}>
                                        <div id="mJtu8jfjnYWSz1Ms" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="uSRYei9rpr5QuUS5" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="K6IEUGrfpdTGtFkj" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="bIi5l8yJi3ysd4f3" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="wSMt0VqYDreKBqh4" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="UGiLthyy4yUTnEUj" style={{ zIndex: 45 }}>
                                        <div id="fxTrXfSe14SKfbO0" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="YvcdsIwek4wwTtDP" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="HQJ8aX5xuRKIIf04" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="zXerIYmtfFsLOJQk" style={{ color: "#272727", fontSize: "0.9em" }}>FRANCE</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="xoMafgtAvI1LYLU1" style={{ display: "grid", position: "relative", gridArea: "9 / 36 / 25 / 43" }}>
                                    <div id="fjKk6ZSZrCZULlyY" style={{ zIndex: 27 }}>
                                        <div id="zcO9Ca3rF7H1FR97" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="eHdKtS6kOpoivc1X" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="UoQ3M12UIw7BRVur" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="brNDTGXduOX6cUzP" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="usA65WfMSFiFM5lk" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="nrlnJvcp8Y3zkbUC" style={{ zIndex: 46 }}>
                                        <div id="MYf2PqNWrMepegI4" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="NCWYz6prWfTZIUj1" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="RPrP6rsGtm00LJDs" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="DP4hImDkFTG8svUJ" style={{ color: "#272727", fontSize: "0.8em" }}>GERMANY</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="hcNJ0iTegRAgMxhc" style={{ display: "grid", position: "relative", gridArea: "10 / 45 / 26 / 51" }}>
                                    <div id="k0SLSDfnTseJYbiL" style={{ zIndex: 28 }}>
                                        <div id="wmt3rNByAFwneXcv" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="PFXW3z8soIR4CVXQ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="S7wEOTDlO9UiOqMT" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="KRoWtVvpLwjyqy0z" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="HMFp7KzwKqRu5TCZ" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="KRjESKscLaVoWvIR" style={{ zIndex: 47 }}>
                                        <div id="MnRautC2nqjbh9Pl" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="lsjuvlXORj2nV5nL" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="OQkAg5JKqcgBVUOy" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="mBwOSHuE3P4UPbat" style={{ color: "#272727", fontSize: "0.8em" }}>ENGLAND</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="CS4rFoR7sl2z68VQ" style={{ display: "grid", position: "relative", gridArea: "11 / 61 / 27 / 65" }}>
                                    <div id="bm0pgSt9OT2OmnyH" style={{ zIndex: 30 }}>
                                        <div id="slwraAIEgIrGdtYe" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="LkgUdZkWXjm7a8G5" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="GusfG8mJv1RKi9jI" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="n48ovbJnqMK3joGE" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="qZepD7JKMGPEg3gY" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="YIAVJVfgieR72Wt5" style={{ zIndex: 49 }}>
                                        <div id="i2o9Xys1FtXzMX8b" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="UdB8eXD7BCuA7l9t" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="vAUcfsoTmobw8zYS" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="II7z52aVxXSBBkB7" style={{ color: "#272727", fontSize: "0.8em" }}>ITALY</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="lA01k4z1D9qW6PSH" style={{ display: "grid", position: "relative", gridArea: "12 / 54 / 28 / 60" }}>
                                    <div id="cp5ejfWQo4GYfgUc" style={{ zIndex: 29 }}>
                                        <div id="CTTeXzReQf5qfBaB" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="eK0i85VYWa70DaGb" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="NlhXN7rOu8f29qoE" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="N2F8q2rZg6fVrBUx" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="wAcyBFJWXegls0T2" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="gyJqLBk66R8arMis" style={{ zIndex: 48 }}>
                                        <div id="PxLMNwri4tvWhI0m" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="ksuIaNssR3HJHutj" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="Weh9cV3o3902Gczk" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="immcNmCkeiTWgMHI" style={{ color: "#272727", fontSize: "0.75em" }}>PORTUGAL</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="pRvAPVDWQBd8mMaB" style={{ zIndex: 3 }}>
                                    <div id="aqxRcNhmJoclotne" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="ze7oLAQ458q79qyG" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="xyBePLtrAlKekHju" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="uqdEjQnVYr09my7X" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="ZpFjkEDgGSkpArJo" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={spain} id="team1" alt="spain" onClick={() => {handleSetTeamSelected("spain", 1);}}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="NKY4eJ5qSHa6SsN1" style={{ zIndex: 4 }}>
                                    <div id="jkFTyc0pMBcaUIee" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="xw3WUyHAnlMHp8qw" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="ugxXoEqDAoht8HtZ" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="lN9jOCh45purNeVG" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="ihwauIejcSqtqfgU" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={austria} id="team2" alt="austria" onClick={() => {handleSetTeamSelected("austria", 2);}}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="MFOhPKP72tOL5Iwn" style={{ zIndex: 5 }}>
                                    <div id="dwYlwmFS0vdSm0eD" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="rEYTiRdbJpYTbw9e" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="yYlnNMGVg6LEqQdI" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="y5VObvhWdgHHGiFN" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="usR0W7oQ16k3hezf" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={denmark} id="team3" alt="denmark" onClick={() => {handleSetTeamSelected("denmark", 3);}}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="gZdByIerXRC0jIHP" style={{ zIndex: 6 }}>
                                    <div id="EIBf8MGdQV37QMpu" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="VhvM9yXvAyT6Q1BJ" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="riJksdO8ThWyzyXf" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="rKxAuiWA2xZOU7ON" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="tiHWHjkrH3nbIBJv" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={france} id="team4" alt="france" onClick={() => {handleSetTeamSelected("france", 4);}}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="j4XrNkOAkpddknBR" style={{ zIndex: 7 }}>
                                    <div id="nujNv5JZgkNZBPfo" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="YlPa1KJzn0TeeeJo" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="VRCxOhAqrLYPwcLk" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="miTQQHrHifoAvqqe" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="h0akuVa5Dkybk8bQ" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={germany} id="team5" alt="germany" onClick={() => {handleSetTeamSelected("germany", 5);}}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="EmW7J9hV7d3CimtZ" style={{ zIndex: 8 }}>
                                    <div id="FtcPCi89Cz8vVBRn" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="ax9Ar3eYyq6IAcjT" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="vkOo0nw80SPbsazZ" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="HhPGBw4LWQrrDde7" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="jNQbaHiyDGdngy3s" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={england} id="team6" alt="england" onClick={() => {handleSetTeamSelected("england", 6); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="ee5NnpHua8fmY3a8" style={{ zIndex: 9 }}>
                                    <div id="HQWYd6wDIjrKE6er" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="JtlpIa0KBH2REyIm" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="WuCz6ApnSVqLRYwf" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="lzsz8sR8upWkSRur" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="sGBnJlEQ3wMPc0Lx" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={portugal} id="team7" alt="portugal" onClick={() => {handleSetTeamSelected("portugal", 7); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="qgafzK3b6ev8K2gS" style={{ zIndex: 10 }}>
                                    <div id="QrWicLSzP4Lgp8N3" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="WajIzrRI9PuIzCDj" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="uCaS6yRHI72hcQjE" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="j6pIFhNMbFnYKp3k" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="ESSxfn4d50QfGeb6" style={{ width: "82%", height: "92%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={italy} id="team8" alt="italy" onClick={() => {handleSetTeamSelected("italy", 8); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="ObwmfRkjJFz8Oa5W" style={{ display: "grid", position: "relative", gridArea: "37 / 6 / 53 / 10" }}>
                                    <div id="wD5SuOmh0m9n3lhQ" style={{ zIndex: 31 }}>
                                        <div id="ML2Sv66C0j73eoA4" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="VST9CfvkyoQGPEmG" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="q8UWctQLXagXdfKk" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="zL2OBCfo9ebOZkQ3" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="h5cYZd9NsFiawy6S" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="xW3TKOPBxRDwx3EA" style={{ zIndex: 50 }}>
                                        <div id="wjGbq5zexxxNAIxg" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="IQalmKrYFndTl7kl" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="pcafU7hJozOLe0mu" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="iosBtmMcjMbljDjt" style={{ color: "#272727", fontSize: "0.5em" }}>SWITZERLAND</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="IceI7mlo4RvCwVvq" style={{ display: "grid", position: "relative", gridArea: "38 / 12 / 54 / 18" }}>
                                    <div id="e1hXFfP7pbwXIz4v" style={{ zIndex: 32 }}>
                                        <div id="tcqqAbHWn2x54TbR" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="dIhdP5PeMLbsc0ym" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="txv91DcyWSD1NiPy" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="GQfHI0IqKAZX3iLl" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="h14rBWNChhr76ZcE" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="R2BnyAnAsbiSJ3mS" style={{ zIndex: 51 }}>
                                        <div id="X12GyiRpG29ggYYW" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="oYRMPR1GfBFj0aXD" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="uEeJt1cNb0qH3P8Z" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="znC1wbROMrX0qqHj" style={{ color: "#272727", fontSize: "0.7em" }}>BELGIUM</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="J4lbmfkCCSKV1l5m" style={{ display: "grid", position: "relative", gridArea: "39 / 20 / 55 / 26" }}>
                                    <div id="tlioFtSWXbFvvJRD" style={{ zIndex: 33 }}>
                                        <div id="IggecpbeaClAuVSV" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="usanSeNlAuL0xdTC" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="VeVSCUHtaGG4Mnul" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="ssT4K93VWowA0gge" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="EYTvZDrmSk0EQKnF" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="bKgUCtdkBF6VdDBq" style={{ zIndex: 52 }}>
                                        <div id="wuvcRHebgQ4xzp6V" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="aySQhGjgRfRTKHgT" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="ufNq6NPD1ZuBDHge" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="Q5SyE0FvHDPTwOeY" style={{ color: "#272727", fontSize: "0.75em" }}>Rumania</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="HosxpsqTp3K7kaSH" style={{ display: "grid", position: "relative", gridArea: "40 / 28 / 56 / 35" }}>
                                    <div id="CtjNgOrQQMfOe8Gx" style={{ zIndex: 34 }}>
                                        <div id="Q0Z8r1QwQ36zAJ3V" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="mSy8ZHxZvSXhIEAg" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="GW578E2y42dwfvH4" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="hgldXnAZqmxPqjFz" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="oLYMfLjawDKx7Wwk" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Y69ymcIuYQUgyl7L" style={{ zIndex: 53 }}>
                                        <div id="rWfYsN4Ga1MTRpwj" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="vc0zQ7VLS6lSzxd0" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="CXl6CBxncGwWLPoy" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="NiZv8bRrk5R0K5Kf" style={{ color: "#272727", fontSize: "0.7em" }}>Slovenia</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="lpyaHq2VBd1otYWX" style={{ display: "grid", position: "relative", gridArea: "41 / 37 / 57 / 44" }}>
                                    <div id="Lu6sSuqcOWiA8rBd" style={{ zIndex: 35 }}>
                                        <div id="UrGraeRdizihfkRv" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="j2kUm5rjWidJmeHF" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="PLGR2k5Qd1qBckMB" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="k8vCF58BSwqqVbqW" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="FbUXrucsDiiZsNeV" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="f0U5TNARLF94eHlh" style={{ zIndex: 54 }}>
                                        <div id="oRntsGbFwKRPBzom" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="Ax9weQShHSnoWyRW" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="RhLiYs9qZaqFQuka" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="aW6olqfROblRaDoh" style={{ color: "#272727", fontSize: "0.5em" }}>NETHERLANDS</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="yHweWET2YDN2qJml" style={{ display: "grid", position: "relative", gridArea: "42 / 46 / 58 / 52" }}>
                                    <div id="k9sS1te8447LuBAJ" style={{ zIndex: 36 }}>
                                        <div id="TZLa5hr6DxxKZf5G" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="ak2aM1xk0xQ7ioml" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="qqSe8eEaK920uZfH" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="ACYLpbJOzYKfre1o" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="fMTpbQGPQBZGcm4Y" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ix7UGIHJkbuxnXH0" style={{ zIndex: 55 }}>
                                        <div id="FHQ7AIujYrKqWS9V" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="ofFYhnmxcHYs6gFo" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="i5B7oQm2U95fjcpE" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="dAjn8yDo574s6beQ" style={{ color: "#272727", fontSize: "0.8em" }}>TURKEY</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="uC0M1G6FfF9aEUaG" style={{ display: "grid", position: "relative", gridArea: "43 / 53 / 59 / 59" }}>
                                    <div id="ogscofpDn7YZrn2d" style={{ zIndex: 37 }}>
                                        <div id="vLNuueNOJZkG0jtB" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="aTVLynuU1ryiYdNu" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="AI6kTi0br5cYaaYE" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="ej13OTOmiiFIkwON" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="CbPW72kD52hY73ij" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="b0uq0tH3cx7PEjkO" style={{ zIndex: 56 }}>
                                        <div id="YtP3ICAgvoVMOCwD" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="zDu92Z4iAjlw0m3w" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="DjSnywsbEus9OfFR" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="Bie7gT8vQbiEKUxB" style={{ color: "#272727", fontSize: "0.7em" }}>Slovakia</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="AfeXkkNv8wxoYrjQ" style={{ display: "grid", position: "relative", gridArea: "44 / 62 / 60 / 66" }}>
                                    <div id="h7cDg5lnhDJLzVRT" style={{ zIndex: 38 }}>
                                        <div id="HSgQbuE8bK4sMDft" style={{ paddingTop: "33.42908987%", transform: "rotate(0deg)" }}>
                                            <div id="a62kKoBm170hxN4C" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                                <svg id="s8Dd6IqJKOnrqs3K" viewBox="0 0 13.4115 4.4833" style={{ width: "100%", height: "100%", opacity: 1.0, overflow: "hidden", position: "absolute", top: "0%", left: "0%", }}>
                                                    <g id="dUQwFvkc29mFzdzz" style={{ transform: "scale(1, 1)" }}>
                                                        <path id="UXgDUihSJFyko8cg" d="M2.24166703,0 L11.16980815,0 C11.76433521,0 12.33451199,0.23617496 12.75490611,0.65656907 13.17530022,1.07696319 13.41147518,1.64713997 13.41147518,2.24166703 L13.41147518,2.24166703 C13.41147518,3.47970555 12.40784666,4.48333406 11.16980815,4.48333406 L2.24166703,4.48333406 C1.64713997,4.48333407 1.07696319,4.24715911 0.65656907,3.82676499 0.23617495,3.40637087 0,2.83619409 0,2.24166703 L0,2.24166703 C0,1.64713997 0.23617495,1.07696319 0.65656907,0.65656907 1.07696319,0.23617496 1.64713997,0 2.24166703,0 Z"
                                                        style={{ fill: "#f4f4f4", opacity: 1.0 }} />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="JCIhVPOYMYojwaNr" style={{ zIndex: 57 }}>
                                        <div id="o8F045PHZFd0qTvn" style={{ boxSizing: "border-box", width: "100%", height: "100%", transform: "rotate(0deg)" }}>
                                            <div id="FAQLT5TwqSCPgb4y" style={{ opacity: 1.0, display: "flex", boxSizing: "border-box", flexDirection: "column", justifyContent: "flex-start", width: "100%", height: "100%" }}>
                                                <p id="qUKCm6PL8rWRm56k" style={{ color: "#272727", fontFamily: "CC-Maladroit", lineHeight: "1.36777199em", textAlign: "center" }}>
                                                    <span id="brZsfy9r7Zjc8KCa" style={{ color: "#272727", fontSize: "0.75em" }}>GEORGIA</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="zfVGMeUn4WiwcyCy" style={{ zIndex: 11 }}>
                                    <div id="XRlEPofy9O2ergA6" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="XF6TQAYSuG8JHPFS" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="kHmZWg87xVU2aglA" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="aUXMb3aJD4Tc1HhT" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="TqKDNyqgKlP2z0lN" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={switzerland} id="team9" alt="switzerland" onClick={() => {handleSetTeamSelected("switzerland", 9); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="YQ6MmAPRIHkWVXVM" style={{ zIndex: 12 }}>
                                    <div id="vuW52CIBciLiChBM" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="kAyRI6kfl6k67YZy" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="U7eVWeVdOR6LylLt" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="eVs5Egb29lo2ODTT" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="GTJib16WUUXULoBu" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={Belgium} id="team10" alt="Belgium" onClick={() => {handleSetTeamSelected("Belgium", 10); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Y964c6bSb9voWOXa" style={{ zIndex: 13 }}>
                                    <div id="A5ES4d4wWC4McdGc" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="x3S8iXRgyPLQakWR" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="y8SWRiLvzP07H21w" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="ZMOVeHnRRrRtASnH" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="UNALI0kHwvC01YwY" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={rumania} id="team11" alt="Rumania" onClick={() => {handleSetTeamSelected("Rumania", 11); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="rGDWuycyanycUN5a" style={{ zIndex: 14 }}>
                                    <div id="EK5TtwKPaR6G7h2o" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="htXBXQNaqxUTpVoD" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="n81Q1v7TjUGBywaj" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="CXP4LYBG73MJf7yf" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="omdq208KIvHVzOZq" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={slovenia} id="team12" alt="Slovenia" onClick={() => {handleSetTeamSelected("Slovenia", 12); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="HJWPpxgoryXTMcK7" style={{ zIndex: 15 }}>
                                    <div id="eO7DRZ12ymSIziGs" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="NChr6tGSAKdzNBlW" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="bk2Ra6BJGxZjJpon" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="LRuDqmsm20vf0fH4" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="mMtjIRcxwRwvUdbF" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={Netherlands} id="team13" alt="Netherlands" onClick={() => {handleSetTeamSelected("Netherlands", 13); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="VNbWjvTTWC08ZmHJ" style={{ zIndex: 16 }}>
                                    <div id="LhLddtyDskGU2vMt" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="yUdYRqEHSqzGVi7L" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="NWNJrUS174QQU1CJ" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="Sv0o8mSCi1V7Z79E" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="P79ueJQZ9x1pD6R7" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={Turkey} id="team14" alt="Turkey" onClick={() => {handleSetTeamSelected("Turkey", 14); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="XHujS9jrCUV3NqAi" style={{ zIndex: 17 }}>
                                    <div id="s1ILY0sRVQRMmIAL" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="TJnOebalPgZvBF5C" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="crCoAaHcC8SkDSaa" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="MVVXfKXWFa1jB40a" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="GBKNprOdQDvAm4KM" style={{ width: "87%", height: "99%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={slovakia} id="team15" alt="Slovakia" onClick={() => {handleSetTeamSelected("Slovakia", 15); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="LUaBb5eplz5FVqtU" style={{ zIndex: 18 }}>
                                    <div id="smNxoJ4LxuTywj8t" style={{ paddingTop: "100%", transform: "rotate(0deg)" }}>
                                        <div id="e889HsJd7sma1XLl" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
                                            <div id="HmgQPTuMGLKxx0wo" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                                                <div id="SK9C6RJeKY5ytq5H" style={{ transform: "scale(1, 1)", width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
                                                    <div id="IjRkC1MjN5bpntpQ" style={{ width: "82%", height: "92%", position: "absolute",  opacity: 1, animation:
                                                    "auto ease 0s 1 normal none running none" }}>
                                                        <img src={georgia} id="team16" alt="Georgia" onClick={() => {handleSetTeamSelected("Georgia", 16); }}  className="equipo" loading="lazy" style={{ width: "100%", height: "100%", display: "block", objectFit: "cover", objectPosition: "50% 50%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/* </span>)} */}
                        </div>
                    </div>)}
                </div>
            </section>
            {/* </LoadingOverlay> */}
        </div>
    );
}

export default Eurocopa;